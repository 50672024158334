html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(16px*0.95);
  line-height: 1.45;
  color: #4E565F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
}
::selection {
  color: #fff;
  background: #00B3E3;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
p {
  margin: calc(16px/4) 0 calc(16px);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-size: inherit;
  line-height: 1.1;
  margin: calc(16px*2) 0 16px;
}
h1 {
  font-size: calc(16px*2.5);
}
h2 {
  font-size: calc(16px*2);
}
h3 {
  font-size: calc(16px*1.5);
}
h4,
h5,
h6 {
  font-size: calc(16px*1.3);
  line-height: 1.4;
  margin: 16px 0 calc(16px/2);
}
h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6 {
  margin-top: 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 16px calc(16px*2);
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  text-decoration: none;
  -webkit-text-decoration-skip: auto;
  text-decoration-skip-ink: auto;
  color: inherit;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
  line-break: strict;
  white-space: nowrap;
  overflow: scroll;
}
dfn {
  font-style: italic;
}
mark {
  background: #E5541A;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -calc(16px/4);
}
sup {
  top: -calc(16px/2);
  vertical-align: super;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
  max-width: 100%;
  font-size: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  line-height: 1.3;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  /* 3 */
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
.lazyload {
  display: none;
}
#viewport {
  position: relative;
}
.wrap--small {
  max-width: 768px;
  margin: 0 auto;
}
.wrap--medium {
  max-width: 960px;
  margin: 0 auto;
}
.wrap--large,
.wrap {
  max-width: 1280px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .content__full {
    padding: 0;
  }
}
.content__full {
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}
[class*="col-fixed-"] {
  float: left;
  width: 100%;
}
.col__group {
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px;
  display: block;
  *zoom: 1;
}
.col__group--full {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
}
.col__group--full .col__group {
  padding: 0;
}
.col__group:before,
.col__group:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.col__group:after {
  clear: both;
}
.col__group .col__row {
  overflow: hidden;
  margin: 0 -16px;
}
.col__group--table .col__row {
  width: 100%;
}
.col__group--flex {
  display: -ms-flex;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  /* direction */
  /* space */
  /* alignment */
}
.col__group--flex:before,
.col__group--flex:after {
  display: none;
}
.col__group--flex--direction-row {
  flex-direction: row;
}
.col__group--flex--direction-row-reverse {
  flex-direction: row-reverse;
}
.col__group--flex--direction-column {
  flex-direction: column;
}
.col__group--flex--direction-column-reverse {
  flex-direction: column-reverse;
}
.col__group--flex--justify-space-around {
  justify-content: space-around;
}
.col__group--flex--justify-space-between {
  justify-content: space-between;
}
.col__group--flex--justify-content--start {
  justify-content: start;
}
.col__group--flex--justify-content--center {
  justify-content: center;
}
.col__group--flex--justify-content--end {
  justify-content: end;
}
.col__group--flex--align-flex-start {
  align-items: flex-start;
}
.col__group--flex--align-start {
  align-items: start;
}
.col__group--flex--align-baseline {
  align-items: baseline;
}
.col__group--flex--align-normal {
  align-items: normal;
}
.col__group--flex--align-center {
  align-items: center;
}
.col__group--flex--align-end {
  align-items: flex-end;
}
.col__group--flex--align-stretch {
  align-items: stretch;
}
.col__group--flex--wrap-wrap {
  flex-wrap: nowrap;
}
.col__group--flex--wrap-nowrap {
  flex-wrap: nowrap;
}
.col__group--flex--wrap-nowrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex {
  display: -ms-flex;
  display: flex;
  /* direction */
  /* space */
  /* alignment */
}
.flex:before,
.flex:after {
  display: none;
}
.flex--direction-row {
  flex-direction: row;
}
.flex--direction-row-reverse {
  flex-direction: row-reverse;
}
.flex--direction-column {
  flex-direction: column;
}
.flex--direction-column-reverse {
  flex-direction: column-reverse;
}
.flex--justify-space-around {
  justify-content: space-around;
}
.flex--justify-space-between {
  justify-content: space-between;
}
.flex--justify-content--start {
  justify-content: start;
}
.flex--justify-content--center {
  justify-content: center;
}
.flex--justify-content--end {
  justify-content: end;
}
.flex--align-flex-start {
  align-items: flex-start;
}
.flex--align-start {
  align-items: start;
}
.flex--align-baseline {
  align-items: baseline;
}
.flex--align-normal {
  align-items: normal;
}
.flex--align-center {
  align-items: center;
}
.flex--align-end {
  align-items: flex-end;
}
.flex--align-stretch {
  align-items: stretch;
}
.flex--wrap-wrap {
  flex-wrap: wrap;
}
.flex--wrap-nowrap {
  flex-wrap: nowrap;
}
.flex--wrap-nowrap-reverse {
  flex-wrap: wrap-reverse;
}
.col__group [class*="col-"] {
  min-height: 1px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  zoom: 1;
  float: left;
}
.col__group [class*="col-"]:after {
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (max-width: 768px) {
  .col__group [class*="col-"].col--center {
    text-align: center;
  }
  .col__group [class*="col-"].col--split {
    width: 50%;
  }
}
@media (min-width: 601px) and (max-width: 768px) {
  .col__group [class*="col-"].col--split-tablet {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .col__group [class*="col-"].col--split-mobile {
    width: 50%;
  }
}
.col__group [class*="col-"] > [class*="col-"].col__group {
  margin: 0;
  padding: 0;
}
.col__group--table .col__group [class*="col-"] {
  display: table-cell;
  float: none;
  vertical-align: middle;
}
.col__group [class*="push-"],
.col__group [class*="pull-"] {
  position: relative;
}
.col-mb-12 {
  width: 100%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-11 {
  width: 91.66666666%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-10 {
  width: 83.33333333%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-9 {
  width: 75%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-8 {
  width: 66.66666666%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-7 {
  width: 58.33333333%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-6 {
  width: 50%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-5 {
  width: 41.66666667%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-4 {
  width: 33.33333333%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-3 {
  width: 25%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-2 {
  width: 16.66666667%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
.col-mb-1 {
  width: 8.33333333%;
  float: left;
  padding-right: calc(32px / 2);
  padding-left: calc(32px / 2);
}
@media (min-width: 481px) {
  .col-tb-12 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 100%;
    float: left;
  }
  .col-tb-11 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 91.66666666%;
    float: left;
  }
  .col-tb-10 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 83.33333333%;
    float: left;
  }
  .col-tb-9 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 75%;
    float: left;
  }
  .col-tb-8 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 66.66666666%;
    float: left;
  }
  .col-tb-7 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 58.33333333%;
    float: left;
  }
  .col-tb-6 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 50%;
    float: left;
  }
  .col-tb-5 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 41.66666667%;
    float: left;
  }
  .col-tb-4 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 33.33333333%;
    float: left;
  }
  .col-tb-3 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 25%;
    float: left;
  }
  .col-tb-2 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 16.66666667%;
    float: left;
  }
  .col-tb-1 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 8.33333333%;
    float: left;
  }
}
@media (min-width: 769px) {
  .col__group .col-12 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 100%;
    float: left;
  }
  .col__group .col-11 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 91.66666666%;
    float: left;
  }
  .col__group .col-10 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 83.33333333%;
    float: left;
  }
  .col__group .col-9 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 75%;
    float: left;
  }
  .col__group .col-8 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 66.66666666%;
    float: left;
  }
  .col__group .col-7 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 58.33333333%;
    float: left;
  }
  .col__group .col-6 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 50%;
    float: left;
  }
  .col__group .col-5 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 41.66666667%;
    float: left;
  }
  .col__group .col-4 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 33.33333333%;
    float: left;
  }
  .col__group .col-3 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 25%;
    float: left;
  }
  .col__group .col-2 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 16.66666667%;
    float: left;
  }
  .col__group .col-1 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 8.33333333%;
    float: left;
  }
}
/*
Desktop and up
*/
@media (min-width: 961px) {
  .col-dt-12 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 100%;
    float: left;
  }
  .col-dt-11 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 91.66666666%;
    float: left;
  }
  .col-dt-10 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 83.33333333%;
    float: left;
  }
  .col-dt-9 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 75%;
    float: left;
  }
  .col-dt-8 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 66.66666666%;
    float: left;
  }
  .col-dt-7 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 58.33333333%;
    float: left;
  }
  .col-dt-6 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 50%;
    float: left;
  }
  .col-dt-5 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 41.66666667%;
    float: left;
  }
  .col-dt-4 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 33.33333333%;
    float: left;
  }
  .col-dt-3 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 25%;
    float: left;
  }
  .col-dt-2 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 16.66666667%;
    float: left;
  }
  .col-dt-1 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 8.33333333%;
    float: left;
  }
}
/*
Large desktop and up
*/
@media (min-width: 1281px) {
  .col-ld-12 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 100%;
    float: left;
  }
  .col-ld-11 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 91.66666666%;
    float: left;
  }
  .col-ld-10 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 83.33333333%;
    float: left;
  }
  .col-ld-9 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 75%;
    float: left;
  }
  .col-ld-8 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 66.66666666%;
    float: left;
  }
  .col-ld-7 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 58.33333333%;
    float: left;
  }
  .col-ld-6 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 50%;
    float: left;
  }
  .col-ld-5 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 41.66666667%;
    float: left;
  }
  .col-ld-4 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 33.33333333%;
    float: left;
  }
  .col-ld-3 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 25%;
    float: left;
  }
  .col-ld-2 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 16.66666667%;
    float: left;
  }
  .col-ld-1 {
    padding-left: calc(32px / 2);
    padding-right: calc(32px / 2);
    width: 8.33333333%;
    float: left;
  }
  .col-ld-offset-12 {
    margin-left: calc(8.33333333% * 12);
  }
  .col-ld-offset-11 {
    margin-left: calc(8.33333333% * 11);
  }
  .col-ld-offset-10 {
    margin-left: calc(8.33333333% * 10);
  }
  .col-ld-offset-9 {
    margin-left: calc(8.33333333% * 9);
  }
  .col-ld-offset-8 {
    margin-left: calc(8.33333333% * 8);
  }
  .col-ld-offset-7 {
    margin-left: calc(8.33333333% * 7);
  }
  .col-ld-offset-6 {
    margin-left: calc(8.33333333% * 6);
  }
  .col-ld-offset-5 {
    margin-left: calc(8.33333333% * 5);
  }
  .col-ld-offset-4 {
    margin-left: calc(8.33333333% * 4);
  }
  .col-ld-offset-3 {
    margin-left: calc(8.33333333% * 3);
  }
  .col-ld-offset-2 {
    margin-left: calc(8.33333333% * 2);
  }
  .col-ld-offset-1 {
    margin-left: calc(8.33333333% * 1);
  }
  .col-ld-offset-0 {
    margin-left: calc(8.33333333% * 0);
  }
  .col-ld-pull-12 {
    right: calc(8.33333333% * 12);
  }
  .col-ld-pull-11 {
    right: calc(8.33333333% * 11);
  }
  .col-ld-pull-10 {
    right: calc(8.33333333% * 10);
  }
  .col-ld-pull-9 {
    right: calc(8.33333333% * 9);
  }
  .col-ld-pull-8 {
    right: calc(8.33333333% * 8);
  }
  .col-ld-pull-7 {
    right: calc(8.33333333% * 7);
  }
  .col-ld-pull-6 {
    right: calc(8.33333333% * 6);
  }
  .col-ld-pull-5 {
    right: calc(8.33333333% * 5);
  }
  .col-ld-pull-4 {
    right: calc(8.33333333% * 4);
  }
  .col-ld-pull-3 {
    right: calc(8.33333333% * 3);
  }
  .col-ld-pull-2 {
    right: calc(8.33333333% * 2);
  }
  .col-ld-pull-1 {
    right: calc(8.33333333% * 1);
  }
  .col-ld-pull-0 {
    right: calc(8.33333333% * 0);
  }
  .col-ld-push-12 {
    left: calc(8.33333333% * 12);
  }
  .col-ld-push-11 {
    left: calc(8.33333333% * 11);
  }
  .col-ld-push-10 {
    left: calc(8.33333333% * 10);
  }
  .col-ld-push-9 {
    left: calc(8.33333333% * 9);
  }
  .col-ld-push-8 {
    left: calc(8.33333333% * 8);
  }
  .col-ld-push-7 {
    left: calc(8.33333333% * 7);
  }
  .col-ld-push-6 {
    left: calc(8.33333333% * 6);
  }
  .col-ld-push-5 {
    left: calc(8.33333333% * 5);
  }
  .col-ld-push-4 {
    left: calc(8.33333333% * 4);
  }
  .col-ld-push-3 {
    left: calc(8.33333333% * 3);
  }
  .col-ld-push-2 {
    left: calc(8.33333333% * 2);
  }
  .col-ld-push-1 {
    left: calc(8.33333333% * 1);
  }
  .col-ld-push-0 {
    left: calc(8.33333333% * 0);
  }
}
/*
Fixed aspect ratio columns
*/
.col-width--100 {
  width: 100%;
}
.col-height--small:not(img) {
  min-height: calc(16px*10);
}
.col-height--medium:not(img) {
  min-height: calc(16px*20);
}
.col-height--large:not(img) {
  min-height: calc(16px*30);
}
.col-min-height--viewport-70:not(img) {
  min-height: 70vh;
}
.col-min-height--viewport-80:not(img) {
  min-height: 80vh;
}
.col-min-height--viewport-90:not(img) {
  min-height: 90vh;
}
.col-min-height--viewport-100:not(img) {
  min-height: 100vh;
}
.col-max-height--viewport-70:not(img) {
  max-height: 70vh;
}
.col-max-height--viewport-80:not(img) {
  max-height: 80vh;
}
.col-max-height--viewport-90:not(img) {
  max-height: 90vh;
}
.col-max-height--viewport-100:not(img) {
  max-height: 100vh;
}
.col-height--viewport-100-max:not(img) {
  max-height: 100vh;
}
.col-height--small-fixed:not(img) {
  height: calc(16px*10);
  overflow: hidden;
}
.col-height--medium-fixed:not(img) {
  height: calc(16px*20);
  overflow: hidden;
}
.col-height--large-fixed:not(img) {
  height: calc(16px*30);
  overflow: hidden;
}
.col-height--viewport-fixed:not(img) {
  height: 100vh;
  overflow: hidden;
}
input[type=range] {
  -webkit-appearance: none;
  margin: 16px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  background: #D0D5DF;
  border-radius: calc(16px/8);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  background: #D0D5DF;
  border-radius: calc(16px/8);
}
input[type=range]::-webkit-slider-thumb {
  height: calc(16px*2);
  width: 16px;
  border-radius: calc(16px/8);
  background: #00B3E3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -calc(8px);
}
input[type=range]::-moz-range-thumb {
  height: calc(16px*2);
  width: 16px;
  border-radius: calc(16px/8);
  background: #00B3E3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -calc(8px);
}
input[type=range]::-ms-thumb {
  height: calc(16px*2);
  width: 16px;
  border-radius: calc(16px/8);
  background: #00B3E3;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -calc(8px);
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #D0D5DF;
}
input[type=range]::-ms-track {
  width: 100%;
  height: calc(16px/2);
  background: #D0D5DF;
  border-radius: calc(16px/8);
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #D0D5DF;
  border-radius: calc(16px/8);
}
input[type=range]::-ms-fill-upper {
  background: #D0D5DF;
  border-radius: calc(16px/8);
}
input[type=range]:focus::-ms-fill-lower {
  background: #D0D5DF;
}
input[type=range]:focus::-ms-fill-upper {
  background: #D0D5DF;
}
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: inherit;
  font-style: italic;
}
input.no-spinner[type=number]::-webkit-inner-spin-button,
input.no-spinner[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form__label {
  display: block;
  box-sizing: border-box;
  border: none;
  padding: calc(16px/2);
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: calc(16px*0.85);
  vertical-align: middle;
  line-height: 1.15;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.form__element {
  box-sizing: border-box;
  border: none;
  display: inline-block;
  border-radius: calc(16px/8);
  background: none;
  padding: 12px;
  transition: all 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(16px*0.85);
  color: inherit;
  line-height: 1.15;
  vertical-align: middle;
  border: 1px solid #D0D5DF;
  caret-color: #00B3E3;
  min-width: 220px;
  max-width: 100%;
}
.form__element--blank {
  border: none;
  min-width: 0;
}
@media (max-width: 600px) {
  .form__element {
    min-width: 100%;
    width: auto;
  }
}
select.form__element {
  height: 40px;
}
.form__element[type=radio] + .form__label,
.form__element[type=checkbox] + .form__label {
  display: inline-block;
  margin-left: calc(16px/4);
  margin-top: calc(16px/4);
}
.form__element[type=checkbox] + .form__label {
  margin-top: 0;
}
.form__element:hover {
  border-color: #4E565F;
}
.form__element:focus {
  border-color: #00B3E3;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}
.form__element--full {
  width: 100%;
}
.form__element--disabled {
  border: none;
  background: none;
}
.form__element--no-border {
  border: none;
  padding: 16px 12px;
}
.form__element--positive {
  border-color: #0CBF65;
  outline: none;
}
.form__element--warning {
  border-color: #E5541A;
  outline: none;
}
.form__element--error,
.form__element--danger {
  border-color: #C62828;
  outline: none;
}
.form__element:focus + .element__tip {
  background: #00B3E3;
}
.form__element--positive + .element__tip {
  background: #0CBF65;
}
.form__element--positive + .element__tip:before {
  content: '\f058';
}
.form__element--warning + .element__tip {
  background: #E5541A;
}
.form__element--warning + .element__tip:before {
  content: '\f071';
}
.form__element--error + .element__tip {
  background: #C62828;
}
.form__element--error + .element__tip:before {
  content: '\f071';
}
.form__require {
  color: #C62828;
  margin-right: calc(16px/4);
  font-weight: 700;
  font-style: italic;
}
.form__group {
  font-size: 0;
  line-height: 0;
  vertical-align: top;
  width: 100%;
  margin-top: calc(16px/4);
}
.form__group .form__element {
  width: 80%;
  font-size: calc(16px*0.85);
}
.form__group .form__label {
  width: 20%;
  background: #F3F4F6;
  border-radius: calc(16px/8) 0 0 calc(16px/8);
  display: inline-block;
  box-sizing: border-box;
  font-size: calc(16px*0.85);
  text-align: center;
  padding: 13px;
}
.form__group .form__element ~ .form__element {
  width: 20%;
  border-radius: calc(16px/8) 0 0 calc(16px/8);
  display: inline-block;
  box-sizing: border-box;
  font-size: calc(16px*0.85);
  text-align: center;
  padding: 13px;
}
.form__group .form__element {
  width: 80%;
  border-right: none;
}
.form__group .form__element ~ .form__label,
.form__group .form__element ~ .form__element {
  border-radius: 0 calc(16px/8) calc(16px/8) 0;
}
.element__tip {
  margin-top: -1px;
  padding: calc(16px/2) calc(16px*0.75) calc(16px/2);
  box-sizing: border-box;
  background: #4E565F;
  color: #fff;
  font-weight: 600;
  font-size: calc(16px*0.75);
  transition: all 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  z-index: 1;
}
.element__tip:before {
  content: '\f05a';
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: calc(16px/4);
}
.select__wrapper {
  position: relative;
}
.select__wrapper:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  width: 20px;
  height: 10px;
  background: url(/dist/select-arrow.svg?e1a38bd75c435c9534105c56d2abdd52) no-repeat;
  margin-top: -5px;
  pointer-events: none;
}
.select__wrapper .form__element {
  -webkit-appearance: none;
  appearance: none;
  background-image: linear-gradient(#FFFFFF 0%, #F7F7F7 100%);
  cursor: pointer;
}
.select__wrapper .form__element:hover {
  background-image: linear-gradient(#FFFFFF 0%, #F7F7F7 100%);
}
.select__wrapper .form__element:focus {
  background-image: linear-gradient(#FFFFFF 0%, #F7F7F7 100%);
  outline: none;
}
.blockquote {
  margin: 16px -16px;
  padding: 16px 16px 16px 32px;
  font-size: calc(16px*0.95);
  border-left: 16px/8 solid #D0D5DF;
}
.blockquote--dark {
  border-color: #000000;
}
.blockquote--heather_grey--4 {
  border-color: #0F1520;
}
.blockquote--heather_grey--3 {
  border-color: #4E565F;
}
.blockquote--heather_grey--2 {
  border-color: #D0D5DF;
}
.blockquote--heather_grey--1 {
  border-color: #F3F4F6;
}
.blockquote--white {
  border-color: #fff;
}
.blockquote--black {
  border-color: #000;
}
.blockquote--overlay {
  border-color: rgba(0, 0, 0, 0.9);
}
.blockquote--positive {
  border-color: #0CBF65;
}
.blockquote--warning {
  border-color: #E5541A;
}
.blockquote--warning-light {
  border-color: #efaf11;
}
.blockquote--danger {
  border-color: #C62828;
}
.blockquote--primary {
  border-color: #00B3E3;
}
.flag {
  content: '';
  padding: 16px/3 16px 16px/3.25;
  font-size: calc(16px*0.75);
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  color: #fff;
  background: #0F1520;
  margin: 0 calc(16px/4) 0;
}
.flag--dark {
  background-color: #000000;
}
.flag--heather_grey--4 {
  background-color: #0F1520;
}
.flag--heather_grey--3 {
  background-color: #4E565F;
}
.flag--heather_grey--2 {
  background-color: #D0D5DF;
  color: #0F1520;
}
.flag--heather_grey--1 {
  background-color: #F3F4F6;
  color: #0F1520;
}
.flag--white {
  background-color: #fff;
  color: #0F1520;
}
.flag--black {
  background-color: #000;
}
.flag--overlay {
  background-color: rgba(0, 0, 0, 0.9);
}
.flag--positive {
  background-color: #0CBF65;
}
.flag--warning {
  background-color: #E5541A;
}
.flag--warning-light {
  background-color: #efaf11;
}
.flag--danger {
  background-color: #C62828;
}
.flag--primary {
  background-color: #00B3E3;
}
.ruif .notification {
  content: '';
  padding: 16px/8 calc(16px/2);
  line-height: 16px;
  font-size: calc(16px*0.65);
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  background: #0F1520;
  border-radius: 16px/8;
  box-sizing: border-box;
  margin: 0 calc(16px/4) 0;
  position: relative;
  top: -16px/8;
}
.ruif .notification--dark {
  background-color: #000000;
  color: #fff;
}
.ruif .notification--heather_grey--4 {
  background-color: #0F1520;
  color: #fff;
}
.ruif .notification--heather_grey--3 {
  background-color: #4E565F;
  color: #fff;
}
.ruif .notification--heather_grey--2 {
  background-color: #D0D5DF;
  color: #fff;
}
.ruif .notification--heather_grey--1 {
  background-color: #F3F4F6;
  color: #0F1520;
}
.ruif .notification--white {
  background-color: #fff;
  color: #0F1520;
}
.ruif .notification--black {
  background-color: #000;
  color: #fff;
}
.ruif .notification--overlay {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}
.ruif .notification--positive {
  background-color: #0CBF65;
  color: #fff;
}
.ruif .notification--neutral {
  background: #fff;
  box-shadow: inset 0px 0px 0px 1px #0F1520;
  color: #0F1520;
}
.ruif .notification--warning {
  background-color: #E5541A;
  color: #fff;
}
.ruif .notification--warning-light {
  background-color: #efaf11;
  color: #fff;
}
.ruif .notification--danger {
  background-color: #C62828;
  color: #fff;
}
.ruif .notification--primary {
  background-color: #00B3E3;
  color: #fff;
}
.ruif .notification--raised {
  position: relative;
  top: -16px;
  left: -16px/4;
}
.ruif .notification--fixed {
  position: absolute;
  top: -16px/2;
  right: -20px;
}
.ruif .message {
  box-sizing: border-box;
  z-index: 999;
  padding: 0;
  margin: 0;
  font-size: calc(16px*0.95);
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  background: #0F1520;
  list-style: none;
  width: 100%;
  text-align: center;
  padding: calc(16px/2);
}
.ruif .message--dark {
  background-color: #000000;
  color: #fff;
}
.ruif .message--heather_grey--4 {
  background-color: #0F1520;
  color: #fff;
}
.ruif .message--heather_grey--3 {
  background-color: #4E565F;
  color: #fff;
}
.ruif .message--heather_grey--2 {
  background-color: #D0D5DF;
  color: #0F1520;
}
.ruif .message--heather_grey--1 {
  background-color: #F3F4F6;
  color: #0F1520;
}
.ruif .message--white {
  background-color: #fff;
  color: #0F1520;
}
.ruif .message--black {
  background-color: #000;
  color: #fff;
}
.ruif .message--overlay {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}
.ruif .message--positive {
  background-color: #0CBF65;
  color: #fff;
}
.ruif .message--warning {
  background-color: #E5541A;
  color: #fff;
}
.ruif .message--warning-light {
  background-color: #efaf11;
  color: #fff;
}
.ruif .message--danger {
  background-color: #C62828;
  color: #fff;
}
.ruif .message--primary {
  background-color: #00B3E3;
  color: #fff;
}
.action {
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: calc(16px/2) calc(16px*1.25) calc(16px/1.5);
  text-transform: uppercase;
  color: #fff;
  border-radius: none;
  background: #0F1520;
  transition: background 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  box-sizing: border-box;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  line-height: initial;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: calc(16px*0.85);
}
.action .fa,
.action .icon {
  width: 16px;
  text-align: center;
  display: inline-block;
  margin-left: calc(16px/4);
  margin-right: calc(16px/4);
  margin-top: 16px/5.333;
  margin-bottom: -16px/5.333;
}
.action--tiny {
  padding: calc(16px/4) calc(16px/2) calc(16px/2.66);
  font-size: calc(16px*0.75);
}
.action--small {
  padding: calc(16px/2) calc(16px*1) calc(16px/1.75);
  font-size: calc(16px*0.75);
}
.action--large {
  padding: 16px calc(16px*2) calc(16px*1.1);
  font-size: calc(16px*0.95);
}
.action--proceed {
  float: right;
}
.action--full {
  display: block;
}
.action:hover {
  cursor: pointer;
  background: #4E565F;
}
.action--primary {
  background: #0B0E14;
}
.action--primary:hover {
  background: #2f3c56;
}
.action--primary:active {
  background: #0B0E14;
}
.action--white {
  background: #fff;
  color: #00B3E3;
}
.action--white:hover {
  background: #F3F4F6;
}
.action--white:active {
  background: #D0D5DF;
}
.action--black {
  background: #000;
}
.action--black:hover {
  background: #333333;
}
.action--black:active {
  background: #000;
}
.action--secondary {
  background: #00B3E3;
  font-weight: bold;
}
.action--secondary:hover {
  background: #05c0ee;
}
.action--secondary:active {
  background: #1394bc;
}
.action--disabled {
  background: #F3F4F6;
  color: #D0D5DF;
  cursor: not-allowed;
}
.action--disabled:hover {
  background: #F3F4F6;
}
.action--disabled:active {
  background: #F3F4F6;
}
.action--positive {
  background: #0CBF65;
}
.action--positive:hover {
  background: #065f32;
}
.action--positive:active {
  background: #0aa758;
}
.action--warning {
  background: #E5541A;
}
.action--warning:hover {
  background: #893210;
}
.action--warning:active {
  background: #ce4c17;
}
.action--danger {
  background: #C62828;
}
.action--danger:hover {
  background: #711717;
}
.action--danger:active {
  background: #b12424;
}
.action--outline {
  transition: box-shadow 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #00B3E3;
  background: none;
  box-shadow: inset 0px 0px 0px 2px;
}
.action--outline:hover {
  box-shadow: inset 0px 0px 2px 2px #D0D5DF;
  color: #D0D5DF;
  background: none;
}
.action--outline:active {
  box-shadow: inset 0px 0px 0px 2px #D0D5DF;
  background: none;
}
.action--bb {
  background: #000;
  padding-left: 16px;
  padding-right: calc(16px*3.75);
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.action--bb:after {
  content: '\f061';
  height: 16px;
  width: 32px;
  display: inline-block;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font: normal normal normal 12px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
}
.action--bb:hover {
  background: #00B3E3;
}
.action--bb:hover:after {
  right: 16px;
}
.action--scrollable {
  width: 100%;
  font-size: calc(16px*0.85);
  padding: calc(16px/2) calc(16px/2.6) calc(16px/1.9);
  transition: box-shadow 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #0F1520;
  background: #ffffff;
}
.action--scrollable:hover {
  box-shadow: inset 0 0 0 16px/8 #F3F4F6;
}
.action--help {
  transition: box-shadow 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  background: #0F1520;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: calc(16px*0.75);
  border-radius: 16px;
}
.action--help:before {
  content: 'i';
}
.action--help:hover {
  background: #4E565F;
}
.action--help:active {
  background: #0F1520;
}
.action--loading {
  background: #0F1520;
  background: linear-gradient(270deg, #4E565F, #D0D5DF);
  background-size: 400% 400%;
  border: none;
  box-shadow: none;
  animation: loadingGradient 2s ease infinite;
  color: #D0D5DF;
  cursor: wait;
}
.action--loading:hover {
  background: linear-gradient(to left, #4E565F, #4E565F);
  box-shadow: none;
}
.action--loading:active {
  background: linear-gradient(to left, #4E565F, #4E565F);
  box-shadow: none;
}
.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  background: none;
  outline: none;
  border: none;
  color: #00B3E3;
  cursor: pointer;
}
.link .fa,
.link .icon {
  width: 16px;
  text-align: center;
  display: inline-block;
  margin-right: calc(16px/4);
  vertical-align: baseline;
}
.link--small {
  font-size: calc(16px*0.75);
}
.link--block {
  padding: 16px 41.6px 17.6px;
}
.link--primary {
  color: #00B3E3;
  font-weight: 600;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
}
.link--primary:hover {
  color: #00c7fc;
}
.link--primary:active {
  color: #009fca;
}
.link--secondary {
  color: #000;
}
.link--secondary:hover {
  color: #00B3E3;
}
.link--secondary:active {
  color: #070a0f;
}
.link--danger {
  color: #C62828;
}
.link--danger:hover {
  color: #d63232;
}
.link--danger:active {
  color: #b12424;
}
.link--warning {
  color: #E5541A;
}
.link--warning:hover {
  color: #e86531;
}
.link--warning:active {
  color: #ce4c17;
}
.link--positive {
  color: #0CBF65;
}
.link--positive:hover {
  color: #0ed772;
}
.link--positive:active {
  color: #0aa758;
}
.link--dark {
  color: #0B0E14;
}
.link--dark:hover {
  color: #000000;
}
.link--dark:active {
  color: #000000;
}
.link--heather_grey--4 {
  color: #0F1520;
}
.link--heather_grey--4:hover {
  color: #000000;
}
.link--heather_grey--4:active {
  color: #000000;
}
.link--heather_grey--3 {
  color: #4E565F;
}
.link--heather_grey--3:hover {
  color: #373d43;
}
.link--heather_grey--3:active {
  color: #373d43;
}
.link--heather_grey--2 {
  color: #D0D5DF;
}
.link--heather_grey--2:hover {
  color: #b2baca;
}
.link--heather_grey--2:active {
  color: #b2baca;
}
.link--heather_grey--1 {
  color: #F3F4F6;
}
.link--heather_grey--1:hover {
  color: #d6d9e0;
}
.link--heather_grey--1:active {
  color: #d6d9e0;
}
.link--white {
  color: #fff;
}
.link--white:hover {
  color: #e6e6e6;
}
.link--white:active {
  color: #e6e6e6;
}
.link--disabled {
  cursor: not-allowed;
  color: #D0D5DF;
}
.link--disabled:hover {
  color: #D0D5DF;
}
.link--disabled:active {
  color: #D0D5DF;
}
.animation__wrapper .animate--fade-enter,
.animation__wrapper .animate--fade-exit {
  will-change: transform, opacity;
}
.animation__wrapper .animate--fade-enter--delay-none,
.animation__wrapper .animate--fade-exit--delay-none {
  animation-delay: 0;
}
.animation__wrapper .animate--fade-enter:nth-child(0),
.animation__wrapper .animate--fade-exit:nth-child(0),
.animation__wrapper .animate--fade-enter--delay-0,
.animation__wrapper .animate--fade-exit--delay-0 {
  animation-delay: 300ms;
}
.animation__wrapper .animate--fade-enter:nth-child(1),
.animation__wrapper .animate--fade-exit:nth-child(1),
.animation__wrapper .animate--fade-enter--delay-1,
.animation__wrapper .animate--fade-exit--delay-1 {
  animation-delay: 600ms;
}
.animation__wrapper .animate--fade-enter:nth-child(2),
.animation__wrapper .animate--fade-exit:nth-child(2),
.animation__wrapper .animate--fade-enter--delay-2,
.animation__wrapper .animate--fade-exit--delay-2 {
  animation-delay: 900ms;
}
.animation__wrapper .animate--fade-enter:nth-child(3),
.animation__wrapper .animate--fade-exit:nth-child(3),
.animation__wrapper .animate--fade-enter--delay-3,
.animation__wrapper .animate--fade-exit--delay-3 {
  animation-delay: 1200ms;
}
.animation__wrapper .animate--fade-enter:nth-child(4),
.animation__wrapper .animate--fade-exit:nth-child(4),
.animation__wrapper .animate--fade-enter--delay-4,
.animation__wrapper .animate--fade-exit--delay-4 {
  animation-delay: 1500ms;
}
.animation__wrapper .animate--fade-enter:nth-child(5),
.animation__wrapper .animate--fade-exit:nth-child(5),
.animation__wrapper .animate--fade-enter--delay-5,
.animation__wrapper .animate--fade-exit--delay-5 {
  animation-delay: 1800ms;
}
.animation__wrapper .animate--fade-enter:nth-child(6),
.animation__wrapper .animate--fade-exit:nth-child(6),
.animation__wrapper .animate--fade-enter--delay-6,
.animation__wrapper .animate--fade-exit--delay-6 {
  animation-delay: 2100ms;
}
.animation__wrapper .animate--fade-enter {
  will-change: transform, opacity;
  opacity: 0;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: 300ms fadeIn forwards cubic-bezier(0.215, 0.61, 0.355, 1), 300ms slideUp forwards cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation__wrapper .animate--fade-exit {
  will-change: transform, opacity;
  opacity: 1;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: 300ms fadeOut forwards cubic-bezier(0.215, 0.61, 0.355, 1), 300ms slideDown forwards cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation__wrapper .animate--fade-in,
.animation__wrapper .animate--fade-out {
  will-change: opacity;
  opacity: 0;
}
.animation__wrapper .animate--fade-in--delay-none,
.animation__wrapper .animate--fade-out--delay-none {
  animation-delay: 0;
}
.animation__wrapper .animate--fade-in:nth-child(0),
.animation__wrapper .animate--fade-out:nth-child(0),
.animation__wrapper .animate--fade-in--delay-0,
.animation__wrapper .animate--fade-out--delay-0 {
  animation-delay: 300ms;
}
.animation__wrapper .animate--fade-in:nth-child(1),
.animation__wrapper .animate--fade-out:nth-child(1),
.animation__wrapper .animate--fade-in--delay-1,
.animation__wrapper .animate--fade-out--delay-1 {
  animation-delay: 600ms;
}
.animation__wrapper .animate--fade-in:nth-child(2),
.animation__wrapper .animate--fade-out:nth-child(2),
.animation__wrapper .animate--fade-in--delay-2,
.animation__wrapper .animate--fade-out--delay-2 {
  animation-delay: 900ms;
}
.animation__wrapper .animate--fade-in:nth-child(3),
.animation__wrapper .animate--fade-out:nth-child(3),
.animation__wrapper .animate--fade-in--delay-3,
.animation__wrapper .animate--fade-out--delay-3 {
  animation-delay: 1200ms;
}
.animation__wrapper .animate--fade-in:nth-child(4),
.animation__wrapper .animate--fade-out:nth-child(4),
.animation__wrapper .animate--fade-in--delay-4,
.animation__wrapper .animate--fade-out--delay-4 {
  animation-delay: 1500ms;
}
.animation__wrapper .animate--fade-in:nth-child(5),
.animation__wrapper .animate--fade-out:nth-child(5),
.animation__wrapper .animate--fade-in--delay-5,
.animation__wrapper .animate--fade-out--delay-5 {
  animation-delay: 1800ms;
}
.animation__wrapper .animate--fade-in:nth-child(6),
.animation__wrapper .animate--fade-out:nth-child(6),
.animation__wrapper .animate--fade-in--delay-6,
.animation__wrapper .animate--fade-out--delay-6 {
  animation-delay: 2100ms;
}
.animation__wrapper .animate--fade-in {
  will-change: opacity;
  opacity: 0;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: 300ms fadeIn forwards cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation__wrapper .animate--fade-out {
  will-change: opacity;
  opacity: 0;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: 300ms fadeOut forwards cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation__wrapper .animate--slide-up,
.animation__wrapper .animate--slide-down {
  will-change: transform;
}
.animation__wrapper .animate--slide-up--delay-none,
.animation__wrapper .animate--slide-down--delay-none {
  animation-delay: 0;
}
.animation__wrapper .animate--slide-up:nth-child(0),
.animation__wrapper .animate--slide-down:nth-child(0),
.animation__wrapper .animate--slide-up--delay-0,
.animation__wrapper .animate--slide-down--delay-0 {
  animation-delay: 300ms;
}
.animation__wrapper .animate--slide-up:nth-child(1),
.animation__wrapper .animate--slide-down:nth-child(1),
.animation__wrapper .animate--slide-up--delay-1,
.animation__wrapper .animate--slide-down--delay-1 {
  animation-delay: 600ms;
}
.animation__wrapper .animate--slide-up:nth-child(2),
.animation__wrapper .animate--slide-down:nth-child(2),
.animation__wrapper .animate--slide-up--delay-2,
.animation__wrapper .animate--slide-down--delay-2 {
  animation-delay: 900ms;
}
.animation__wrapper .animate--slide-up:nth-child(3),
.animation__wrapper .animate--slide-down:nth-child(3),
.animation__wrapper .animate--slide-up--delay-3,
.animation__wrapper .animate--slide-down--delay-3 {
  animation-delay: 1200ms;
}
.animation__wrapper .animate--slide-up:nth-child(4),
.animation__wrapper .animate--slide-down:nth-child(4),
.animation__wrapper .animate--slide-up--delay-4,
.animation__wrapper .animate--slide-down--delay-4 {
  animation-delay: 1500ms;
}
.animation__wrapper .animate--slide-up:nth-child(5),
.animation__wrapper .animate--slide-down:nth-child(5),
.animation__wrapper .animate--slide-up--delay-5,
.animation__wrapper .animate--slide-down--delay-5 {
  animation-delay: 1800ms;
}
.animation__wrapper .animate--slide-up:nth-child(6),
.animation__wrapper .animate--slide-down:nth-child(6),
.animation__wrapper .animate--slide-up--delay-6,
.animation__wrapper .animate--slide-down--delay-6 {
  animation-delay: 2100ms;
}
.animation__wrapper .animate--slide-up {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: 300ms slideUp forwards cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation__wrapper .animate--slide-down {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: 300ms slideDown forwards cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation__wrapper .animate--scroll-right {
  animation: 90s scrollRight infinite linear;
  background-size: auto 100%;
  background-position: 100% 0;
  width: 100%;
  height: 100%;
  will-change: background;
  transform: translateZ(0);
}
.animation__wrapper .animate--scroll-right--duration-0 {
  animation-duration: 60s;
}
.animation__wrapper .animate--scroll-right--duration-1 {
  animation-duration: 70s;
}
.animation__wrapper .animate--scroll-right--duration-2 {
  animation-duration: 120s;
}
@keyframes slideUp {
  from {
    transform: translate(0, 16px/2);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes slideDown {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 16px/2);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes scrollRight {
  100% {
    background-position: -1000% 0px;
  }
}
.heading {
  font-weight: 700;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  line-height: 1.1;
}
.heading--one {
  font-size: calc(16px*3);
}
@media (max-width: 480px) {
  .heading--one {
    font-size: calc(16px*2.5);
  }
}
.heading--two {
  font-size: calc(16px*2.5);
}
@media (max-width: 480px) {
  .heading--two {
    font-size: calc(16px*2);
  }
}
.heading--three {
  font-size: calc(16px*2);
}
@media (max-width: 480px) {
  .heading--three {
    font-size: calc(16px*1.5);
  }
}
.heading--four {
  font-size: calc(16px*1.5);
}
@media (max-width: 480px) {
  .heading--four {
    font-size: calc(16px*1.3);
  }
}
.heading--five {
  font-size: calc(16px*1.3);
}
@media (max-width: 480px) {
  .heading--five {
    font-size: calc(16px*0.95);
  }
}
.heading--six {
  font-size: calc(16px*0.95);
}
@media (max-width: 480px) {
  .heading--six {
    font-size: calc(16px*0.85);
  }
}
.heading--keyword {
  font-size: 100vh;
  margin-top: -6vh;
  font-weight: 700;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  color: #F3F4F6;
  letter-spacing: -1vw;
  z-index: -1;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
  max-width: 100vw;
  overflow: hidden;
  text-transform: none;
}
.heading--quote {
  font-weight: 600;
  position: relative;
  display: block;
  padding: calc(16px*1.25) calc(16px*1.25) calc(16px*1.5);
}
@media (max-width: 480px) {
  .heading--quote {
    font-size: calc(16px*0.85);
  }
}
.heading--quote .quote__attribute {
  position: absolute;
  bottom: 0;
  left: -calc(16px/4);
  width: 100%;
  font-weight: normal;
  font-style: italic;
}
.heading--quote:before,
.heading--quote:after {
  font-size: 0.9em;
  display: inline-block;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  text-align: center;
  width: calc(16px*1.25);
}
.heading--quote:before {
  content: '\f10d';
  position: relative;
}
.heading--quote:after {
  content: '\f10e';
  position: relative;
}
.font-family--standard {
  font-family: Helvetica, Arial, sans-serif;
}
.font-family--featured {
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
}
.color--primary-key,
.color--primary {
  color: #00B3E3;
}
.color--black {
  color: #000;
}
.color--white {
  color: #fff;
}
.color--heather_grey--4 {
  color: #0F1520;
}
.color--heather_grey--3 {
  color: #4E565F;
}
.color--heather_grey--2 {
  color: #D0D5DF;
}
.color--heather_grey--1 {
  color: #F3F4F6;
}
.color--positive,
.color--fell-green {
  color: #0CBF65;
}
.color--warning,
.color--burnt-orange {
  color: #E5541A;
}
.color--warning-light,
.color--jersey-yellow {
  color: #efaf11;
}
.color--danger,
.color--lancashire-red {
  color: #C62828;
}
.font-weight--light {
  font-weight: 100;
}
.font-weight--book {
  font-weight: 300;
}
.font-weight--normal {
  font-weight: 400;
}
.font-weight--emph {
  font-weight: 600;
}
.font-weight--bold {
  font-weight: 700;
}
.font-style--italic {
  font-style: italic;
}
.font-style--oblique {
  font-style: oblique;
}
.font-style--normal {
  font-style: normal;
}
.font-size--tiny {
  font-size: calc(16px*0.65);
}
.font-size--sub,
.font-size--small {
  font-size: calc(16px*0.75);
}
.font-size--reduced {
  font-size: calc(16px*0.85);
}
.font-size--normal {
  font-size: calc(16px*0.95);
}
.font-size--emph {
  font-size: calc(16px*1.3);
}
.font-size--subtitle,
.font-size--subheading {
  font-size: calc(16px*1.5);
}
.font-size--heading {
  font-size: calc(16px*2);
}
.font-size--display {
  font-size: calc(16px*2.5);
}
.font-size--feature {
  font-size: calc(16px*3);
}
.font-size--inherit {
  font-size: inherit;
}
.line-height--tiny {
  line-height: 0.8;
}
.line-height--sub,
.line-height--small {
  line-height: 0.9;
}
.line-height--reduced {
  line-height: 1.2;
}
.line-height--normal {
  line-height: 1.45;
}
.line-height--emph {
  line-height: 1.7;
}
.line-height--subtitle,
.line-height--subheading {
  line-height: 1.8;
}
.line-height--heading {
  line-height: 2;
}
.line-height--display {
  line-height: 2.5;
}
.line-height--feature {
  line-height: 3;
}
.line-height--inherit {
  line-height: inherit;
}
.panel {
  position: relative;
  overflow: hidden;
  word-break: break-word;
}
.panel--light {
  background-color: #F3F4F6;
  color: #000;
}
.panel--dark {
  background-color: #0B0E14;
  color: #fff;
}
.panel--valley-dark {
  background-repeat: no-repeat;
  background-size: 250%;
  background-position: center;
}
.panel--valley-light {
  background-repeat: no-repeat;
  background-size: 250%;
  background-position: center;
}
.panel--valley-broken-dark {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top left;
}
.panel--valley-broken-light {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top left;
}
.panel--dark {
  background-color: #0B0E14;
  color: #F3F4F6;
}
.panel--heather_grey--4,
.panel--secondary {
  background-color: #0F1520;
  color: #F3F4F6;
}
.panel--heather_grey--3 {
  background-color: #4E565F;
  color: #fff;
}
.panel--heather_grey--2 {
  background-color: #D0D5DF;
  color: #000;
}
.panel--heather_grey--1 {
  background-color: #F3F4F6;
  color: #0B0E14;
}
.panel--river_blue--4 {
  background-color: #1394bc;
  color: #fff;
}
.panel--river_blue--3 {
  background-color: #0ea3cd;
  color: #fff;
}
.panel--river_blue--2 {
  background-color: #05c0ee;
  color: #fff;
}
.panel--river_blue--1 {
  background-color: #00cfff;
  color: #fff;
}
.panel--white {
  background-color: #fff;
  color: #000;
}
.panel--black {
  background-color: #000;
  color: #D0D5DF;
}
.panel--overlay {
  background-color: rgba(0, 0, 0, 0.9);
  color: #D0D5DF;
}
.panel--overlay-light {
  background-color: rgba(255, 255, 255, 0.9);
  color: #4E565F;
}
.panel--positive {
  background-color: #0CBF65;
  color: #fff;
}
.panel--warning {
  background-color: #E5541A;
  color: #fff;
}
.panel--warning-light {
  background-color: #efaf11;
  color: #fff;
}
.panel--danger {
  background-color: #C62828;
  color: #fff;
}
.panel--primary {
  background-color: #00B3E3;
  color: #fff;
}
.panel--code {
  background-color: #000000;
  color: #F3F4F6;
  overflow-x: scroll;
}
.panel--gradient-primary {
  background-image: linear-gradient(-134deg, #00B3E3 0%, #0F1520 100%);
}
.panel--background {
  background-color: #000;
  color: #fff;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.panel--logo-bg {
  background-color: #000;
  background-position: top left;
  background-size: cover;
}
.panel--image,
.panel--image-half {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
  display: block;
  min-height: 100%;
  max-width: none;
  min-width: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 1;
  transition: opacity 0.3s;
}
.panel--image ~ *,
.panel--image-half ~ * {
  position: relative;
  z-index: 1;
}
.panel--image-half {
  max-width: 50vw;
  max-height: 50vh;
}
.panel--overflow-h {
  max-width: 100%;
  overflow-x: scroll;
}
.panel--overflow-v {
  max-height: 100%;
  overflow-y: scroll;
}
.panel--menu {
  position: fixed;
  z-index: 100;
  height: 100vh;
  box-sizing: border-box;
}
.panel--menu__content {
  position: absolute;
  z-index: 100;
  height: 100%;
  box-sizing: border-box;
  padding: calc(16px*3);
  overflow-y: scroll;
  width: 100%;
}
.panel--menu__action {
  transition: box-shadow 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
  background: #0F1520;
  padding: 16px;
  width: 100%;
  font-size: calc(16px*0.95);
  z-index: 101;
}
.panel--menu__action:after {
  display: inline-block;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  font: normal normal normal 12px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
}
.panel--menu__action:after {
  content: '\f0c9';
}
@media screen and (min-width: 769px) {
  .panel--menu__action {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .panel--menu__content {
    display: none;
    background: #0F1520;
  }
}
.panel--fade-in:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+10,ffffff+100&1+10,0+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */
  width: 10%;
  z-index: 999;
  visibility: visible;
}
.panel--fade-out:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+90&0+0,1+90 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 90%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
  /* IE6-9 */
  width: 10%;
  z-index: 999;
  visibility: visible;
}
.panel--video {
  position: relative;
  padding-bottom: 56.25%;
}
.panel--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.incline {
  padding: 12%;
}
.incline--vertical {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 100.5% auto;
}
.incline--horizontal {
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100.5% auto;
  padding: 24% 0 0;
}
.incline.overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}
.banner {
  padding: calc(16px*0.75) calc(16px*3);
  text-align: left;
  position: relative;
}
.banner:before {
  content: '\f071';
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: calc(16px/4);
  height: 16px;
  width: 16px;
  position: absolute;
  top: 16px;
  left: 16px;
  color: #fff;
}
.banner--positive {
  background-color: #0CBF65;
  color: #fff;
}
.banner--positive:before {
  content: '\f00c';
}
.banner--warning {
  background-color: #E5541A;
  color: #fff;
}
.banner--danger {
  background-color: #C62828;
  color: #fff;
}
.u-no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.u-cf {
  *zoom: 1;
}
.u-cf:before,
.u-cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.u-cf:after {
  clear: both;
}
.u-display--none {
  display: none;
}
.u-display--block {
  display: block;
}
.u-display--inline {
  display: inline;
}
.u-display--inline-block {
  display: inline-block;
}
.u-list-style--none {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-overflow--hidden {
  overflow: hidden;
}
.u-overflow--visible {
  overflow: visible;
}
.u-overflow--auto {
  overflow: auto;
}
.u-overflow--scroll {
  overflow: scroll;
}
.u-overflow-x--scroll {
  overflow-x: scroll;
}
.u-overflow-y--scroll {
  overflow-y: scroll;
}
.u-background-size--cover {
  background-size: cover;
}
.u-background-size--contain {
  background-size: contain;
}
.u-background-size--auto {
  background-size: auto;
}
.u-background-attachment--fixed {
  background-attachment: fixed;
}
.u-background-position--center {
  background-position: center;
}
.u-background-position--top {
  background-position: top;
}
.u-background-position--auto {
  background-position: auto;
}
.u-white-space--normal {
  white-space: normal;
}
.u-white-space--nowrap {
  white-space: nowrap;
}
.u-white-space--pre {
  white-space: pre;
}
.u-text-align--left {
  text-align: left;
}
.u-text-align--center {
  text-align: center;
}
.u-text-align--right {
  text-align: right;
}
.u-text-decoration--underline {
  text-decoration: underline;
}
.u-text-decoration--overline {
  text-decoration: overline;
}
.u-text-decoration--line-through {
  text-decoration: line-through;
}
.u-text-decoration-style--solid {
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
}
.u-text-decoration-style--double {
  -webkit-text-decoration-style: double;
          text-decoration-style: double;
}
.u-text-decoration-style--dotted {
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
}
.u-text-decoration-style--dashed {
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
}
.u-text-decoration-style--wavy {
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
}
.u-text-transform--uppercase {
  text-transform: uppercase;
}
.u-text-transform--capitalize {
  text-transform: capitalize;
}
.u-text-transform--lowercase {
  text-transform: lowercase;
}
.u-text-transform--none {
  text-transform: none;
}
.u-font-style--italic {
  font-style: italic;
}
.u-font-style--oblique {
  font-style: oblique;
}
.u-font-style--normal {
  font-style: normal;
}
.u-font-weight--light {
  font-weight: 100;
}
.u-font-weight--book {
  font-weight: 300;
}
.u-font-weight--normal {
  font-weight: 400;
}
.u-font-weight--emph {
  font-weight: 600;
}
.u-font-weight--bold {
  font-weight: 700;
}
.u-font-weight--inherit {
  font-weight: inherit;
}
.u-transition--all {
  transition: all 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--width {
  transition: width 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--height {
  transition: height 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--opacity {
  transition: opacity 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--shadow {
  transition: box-shadow 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--filter {
  transition: filter 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--transform {
  transition: transform 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--color {
  transition: color 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--background {
  transition: background 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--background-color {
  transition: background-color 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-transition--border {
  transition: border 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.u-vertical-align--top {
  vertical-align: top;
}
.u-vertical-align--middle {
  vertical-align: middle;
}
.u-vertical-align--bottom {
  vertical-align: bottom;
}
.u-vertical-align--baseline {
  vertical-align: baseline;
}
.u-expanded {
  width: 125%;
  max-width: 100vw;
  margin: 16px 0 16px -12.5%;
}
.u-expanded--large {
  width: 150%;
  max-width: 100vw;
  margin: 16px 0 16px -25%;
}
@media (max-width: 960px) {
  .u-expanded {
    width: auto;
    max-width: 100%;
    margin: 16px 0;
  }
}
.u-margin--none {
  margin: 0;
}
.u-margin--eighth {
  margin: calc(16px/8);
}
.u-margin--quarter {
  margin: calc(16px/4);
}
.u-margin--half {
  margin: calc(16px/2);
}
.u-margin--three-quarter {
  margin: calc(16px/4*3);
}
.u-margin {
  margin: 16px;
}
.u-margin--two {
  margin: calc(16px*2);
}
.u-margin--three {
  margin: calc(16px*3);
}
.u-margin--four {
  margin: calc(16px*4);
}
.u-margin--five {
  margin: calc(16px*5);
}
.u-margin--six {
  margin: calc(16px*6);
}
.u-margin-top--none {
  margin-top: 0;
}
.u-margin-top--eighth {
  margin-top: calc(16px/8);
}
.u-margin-top--quarter {
  margin-top: calc(16px/4);
}
.u-margin-top--half {
  margin-top: calc(16px/2);
}
.u-margin-top--three-quarter {
  margin-top: calc(16px/4*3);
}
.u-margin-top {
  margin-top: 16px;
}
.u-margin-top--two {
  margin-top: calc(16px*2);
}
.u-margin-top--three {
  margin-top: calc(16px*3);
}
.u-margin-top--four {
  margin-top: calc(16px*4);
}
.u-margin-top--five {
  margin-top: calc(16px*5);
}
.u-margin-top--six {
  margin-top: calc(16px*6);
}
.u-margin-right--none {
  margin-right: 0;
}
.u-margin-right--eighth {
  margin: calc(16px/8);
}
.u-margin-right--quarter {
  margin-right: calc(16px/4);
}
.u-margin-right--half {
  margin-right: calc(16px/2);
}
.u-margin-right--three-quarter {
  margin-right: calc(16px/4*3);
}
.u-margin-right {
  margin-right: 16px;
}
.u-margin-right--auto {
  margin-right: auto;
}
.u-margin-right--two {
  margin-right: calc(16px*2);
}
.u-margin-right--three {
  margin-right: calc(16px*3);
}
.u-margin-right--four {
  margin-right: calc(16px*4);
}
.u-margin-right--five {
  margin-right: calc(16px*5);
}
.u-margin-right--six {
  margin-right: calc(16px*6);
}
.u-margin-bottom--none {
  margin-bottom: 0;
}
.u-margin-bottom--eighth {
  margin-bottom: calc(16px/8);
}
.u-margin-bottom--quarter {
  margin-bottom: calc(16px/4);
}
.u-margin-bottom--half {
  margin-bottom: calc(16px/2);
}
.u-margin-bottom--three-quarter {
  margin-bottom: calc(16px/4*3);
}
.u-margin-bottom {
  margin-bottom: 16px;
}
.u-margin-bottom--two {
  margin-bottom: calc(16px*2);
}
.u-margin-bottom--three {
  margin-bottom: calc(16px*3);
}
.u-margin-bottom--four {
  margin-bottom: calc(16px*4);
}
.u-margin-bottom--five {
  margin-bottom: calc(16px*5);
}
.u-margin-bottom--six {
  margin-bottom: calc(16px*6);
}
.u-margin-left--none {
  margin-left: 0;
}
.u-margin-left--eighth {
  margin-left: calc(16px/8);
}
.u-margin-left--quarter {
  margin-left: calc(16px/4);
}
.u-margin-left--half {
  margin-left: calc(16px/2);
}
.u-margin-left--three-quarter {
  margin-left: calc(16px/4*3);
}
.u-margin-left {
  margin-left: 16px;
}
.u-margin-left--auto {
  margin-left: auto;
}
.u-margin-left--two {
  margin-left: calc(16px*2);
}
.u-margin-left--three {
  margin-left: calc(16px*3);
}
.u-margin-left--four {
  margin-left: calc(16px*4);
}
.u-margin-left--five {
  margin-left: calc(16px*5);
}
.u-margin-left--six {
  margin-left: calc(16px*6);
}
.u-margin--auto {
  margin-left: auto;
  margin-right: auto;
}
.u-margin--minus-h {
  margin-left: -16px;
  margin-right: -16px;
}
.u-margin--minus-v {
  margin-top: -16px;
  margin-bottom: -16px;
}
.u-margin--minus-v-half {
  margin-top: calc(-16px/2);
  margin-bottom: calc(-16px/2);
}
.u-margin--minus-small {
  margin-left: -1px;
  margin-right: -1px;
}
.u-margin--minus-left {
  margin-left: -16px;
}
.u-margin--minus-right {
  margin-right: -16px;
}
.u-margin--minus-left-two {
  margin-left: -32px;
}
.u-margin--minus-right-two {
  margin-right: -32px;
}
.u-padding--none {
  padding: 0;
}
.u-padding--eighth {
  padding: calc(16px/8);
}
.u-padding--quarter {
  padding: calc(16px/4);
}
.u-padding--half {
  padding: calc(16px/2);
}
.u-padding--three-quarter {
  padding: calc(16px/4*3);
}
.u-padding {
  padding: 16px;
}
.u-padding--two {
  padding: calc(16px*2);
}
.u-padding--three {
  padding: calc(16px*3);
}
.u-padding--four {
  padding: calc(16px*4);
}
.u-padding--five {
  padding: calc(16px*5);
}
.u-padding--six {
  padding: calc(16px*6);
}
.u-padding-top--none {
  padding-top: 0;
}
.u-padding-top--eighth {
  padding-top: calc(16px/8);
}
.u-padding-top--quarter {
  padding-top: calc(16px/4);
}
.u-padding-top--half {
  padding-top: calc(16px/2);
}
.u-padding-top--three-quarter {
  padding-top: calc(16px/4*3);
}
.u-padding-top {
  padding-top: 16px;
}
.u-padding-top--two {
  padding-top: calc(16px*2);
}
.u-padding-top--three {
  padding-top: calc(16px*3);
}
.u-padding-top--four {
  padding-top: calc(16px*4);
}
.u-padding-top--five {
  padding-top: calc(16px*5);
}
.u-padding-top--six {
  padding-top: calc(16px*6);
}
.u-padding-right--none {
  padding-right: 0;
}
.u-padding-right--eighth {
  padding-right: calc(16px/8);
}
.u-padding-right--quarter {
  padding-right: calc(16px/4);
}
.u-padding-right--half {
  padding-right: calc(16px/2);
}
.u-padding-right--three-quarter {
  padding-right: calc(16px/4*3);
}
.u-padding-right {
  padding-right: 16px;
}
.u-padding-right--two {
  padding-right: calc(16px*2);
}
.u-padding-right--three {
  padding-right: calc(16px*3);
}
.u-padding-right--four {
  padding-right: calc(16px*4);
}
.u-padding-right--five {
  padding-right: calc(16px*5);
}
.u-padding-right--six {
  padding-right: calc(16px*6);
}
.u-padding-bottom--none {
  padding-bottom: 0;
}
.u-padding-bottom--eighth {
  padding-bottom: calc(16px/8);
}
.u-padding-bottom--quarter {
  padding-bottom: calc(16px/4);
}
.u-padding-bottom--half {
  padding-bottom: calc(16px/2);
}
.u-padding-bottom--three-quarter {
  padding-bottom: calc(16px/4*3);
}
.u-padding-bottom {
  padding-bottom: 16px;
}
.u-padding-bottom--two {
  padding-bottom: calc(16px*2);
}
.u-padding-bottom--three {
  padding-bottom: calc(16px*3);
}
.u-padding-bottom--four {
  padding-bottom: calc(16px*4);
}
.u-padding-bottom--five {
  padding-bottom: calc(16px*5);
}
.u-padding-bottom--six {
  padding-bottom: calc(16px*6);
}
.u-padding-left--none {
  padding-left: 0;
}
.u-padding-left--eighth {
  padding-left: calc(16px/8);
}
.u-padding-left--quarter {
  padding-left: calc(16px/4);
}
.u-padding-left--half {
  padding-left: calc(16px/2);
}
.u-padding-left--three-quarter {
  padding-left: calc(16px/4*3);
}
.u-padding-left {
  padding-left: 16px;
}
.u-padding-left--two {
  padding-left: calc(16px*2);
}
.u-padding-left--three {
  padding-left: calc(16px*3);
}
.u-padding-left--four {
  padding-left: calc(16px*4);
}
.u-padding-left--five {
  padding-left: calc(16px*5);
}
.u-padding-left--six {
  padding-left: calc(16px*6);
}
.u-position--absolute {
  position: absolute;
}
.u-position--relative {
  position: relative;
}
.u-position--fixed {
  position: fixed;
}
.u-position--sticky {
  position: sticky;
}
.u-height--100vh {
  height: 100vh;
}
.u-height--100perc {
  height: 100%;
}
.u-width--100vw {
  height: 100vw;
}
.u-width--100perc {
  height: 100%;
}
.u-position--absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
}
.u-position--absolute-center-fill {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}
.u-position--absolute-top {
  position: absolute;
  top: 0;
}
.u-position--absolute-bottom {
  position: absolute;
  bottom: 0;
}
.u-position--absolute-right {
  position: absolute;
  right: 0;
}
.u-position--absolute-left {
  position: absolute;
  left: 0;
}
.u-position--fixed-center {
  position: fixed;
  top: 50vh;
  left: 50vw;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
}
.u-position--fixed-bottom {
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  z-index: 10;
}
.u-position--fixed-right {
  position: fixed;
  top: 0%;
  right: 0%;
}
.u-position-fixed-left {
  position: fixed;
  top: 0%;
  left: 0%;
}
.u-z-index--minus-1 {
  z-index: -1;
}
.u-z-index--0 {
  z-index: 0;
}
.u-z-index--5 {
  z-index: 5;
}
.u-z-index--10 {
  z-index: 10;
}
.u-z-index--20 {
  z-index: 20;
}
.u-z-index--30 {
  z-index: 30;
}
.u-desktop--large,
.u-desktop,
.u-tablet,
.u-mobile {
  display: none;
}
.u-border-radius {
  border-radius: calc(16px/8);
  overflow: hidden;
}
.u-border--white {
  border: calc(16px/8) solid #fff;
}
.u-border--light-thin {
  border: 1px solid #D0D5DF;
}
.u-border--light {
  border: calc(16px/8) solid #D0D5DF;
}
.u-border-top--light-thin {
  border-top: 1px solid #D0D5DF;
}
.u-border-top--light {
  border-top: calc(16px/8) solid #D0D5DF;
}
.u-border-bottom--light-thin {
  border-bottom: 1px solid #D0D5DF;
}
.u-border-bottom--light {
  border-bottom: calc(16px/8) solid #D0D5DF;
}
.u-border-left--light-thin {
  border-left: 1px solid #D0D5DF;
}
.u-border-left--light {
  border-left: calc(16px/8) solid #D0D5DF;
}
.u-border-right--light-thin {
  border-right: 1px solid #D0D5DF;
}
.u-border-right--light {
  border-right: calc(16px/8) solid #D0D5DF;
}
.u-border--dark-thin {
  border: 1px solid #0F1520;
}
.u-border--dark {
  border: calc(16px/8) solid #0F1520;
}
.u-border-top--dark-thin {
  border-top: 1px solid #0F1520;
}
.u-border-top--dark {
  border-top: calc(16px/8) solid #0F1520;
}
.u-border-bottom--dark-thin {
  border-bottom: 1px solid #0F1520;
}
.u-border-bottom--dark {
  border-bottom: calc(16px/8) solid #0F1520;
}
.u-border-left--dark-thin {
  border-left: 1px solid #0F1520;
}
.u-border-left--dark {
  border-left: calc(16px/8) solid #0F1520;
}
.u-border-right--dark-thin {
  border-right: 1px solid #0F1520;
}
.u-border-right--dark {
  border-right: calc(16px/8) solid #0F1520;
}
.u-border--primary-thin {
  border: 1px solid #00B3E3;
}
.u-border--primary {
  border: calc(16px/8) solid #00B3E3;
}
.u-border-top--primary-thin {
  border-top: 1px solid #00B3E3;
}
.u-border-top--primary {
  border-top: calc(16px/8) solid #00B3E3;
}
.u-border-bottom--primary-thin {
  border-bottom: 1px solid #00B3E3;
}
.u-border-bottom--primary {
  border-bottom: calc(16px/8) solid #00B3E3;
}
.u-border-left--primary-thin {
  border-left: 1px solid #00B3E3;
}
.u-border-left--primary {
  border-left: calc(16px/8) solid #00B3E3;
}
.u-border-right--primary-thin {
  border-right: 1px solid #00B3E3;
}
.u-border-right--primary {
  border-right: calc(16px/8) solid #00B3E3;
}
.u-border--positive-thin {
  border: 1px solid #0CBF65;
}
.u-border--positive {
  border: calc(16px/8) solid #0CBF65;
}
.u-border-top--positive-thin {
  border-top: 1px solid #0CBF65;
}
.u-border-top--positive {
  border-top: calc(16px/8) solid #0CBF65;
}
.u-border-bottom--positive-thin {
  border-bottom: 1px solid #0CBF65;
}
.u-border-bottom--positive {
  border-bottom: calc(16px/8) solid #0CBF65;
}
.u-border-left--positive-thin {
  border-left: 1px solid #0CBF65;
}
.u-border-left--positive {
  border-left: calc(16px/8) solid #0CBF65;
}
.u-border-right--positive-thin {
  border-right: 1px solid #0CBF65;
}
.u-border-right--positive {
  border-right: calc(16px/8) solid #0CBF65;
}
.u-border--warning-light-thin {
  border: 1px solid #efaf11;
}
.u-border--warning-light {
  border: calc(16px/8) solid #efaf11;
}
.u-border-top--warning-light-thin {
  border-top: 1px solid #efaf11;
}
.u-border-top--warning-light {
  border-top: calc(16px/8) solid #efaf11;
}
.u-border-bottom--warning-light-thin {
  border-bottom: 1px solid #efaf11;
}
.u-border-bottom--warning-light {
  border-bottom: calc(16px/8) solid #efaf11;
}
.u-border-left--warning-light-thin {
  border-left: 1px solid #efaf11;
}
.u-border-left--warning-light {
  border-left: calc(16px/8) solid #efaf11;
}
.u-border-right--warning-light-thin {
  border-right: 1px solid #efaf11;
}
.u-border-right--warning-light {
  border-right: calc(16px/8) solid #efaf11;
}
.u-border--warning-thin {
  border: 1px solid #E5541A;
}
.u-border--warning {
  border: calc(16px/8) solid #E5541A;
}
.u-border-top--warning-thin {
  border-top: 1px solid #E5541A;
}
.u-border-top--warning {
  border-top: calc(16px/8) solid #E5541A;
}
.u-border-bottom--warning-thin {
  border-bottom: 1px solid #E5541A;
}
.u-border-bottom--warning {
  border-bottom: calc(16px/8) solid #E5541A;
}
.u-border-left--warning-thin {
  border-left: 1px solid #E5541A;
}
.u-border-left--warning {
  border-left: calc(16px/8) solid #E5541A;
}
.u-border-right--warning-thin {
  border-right: 1px solid #E5541A;
}
.u-border-right--warning {
  border-right: calc(16px/8) solid #E5541A;
}
.u-border--danger-thin {
  border: 1px solid #C62828;
}
.u-border--danger {
  border: calc(16px/8) solid #C62828;
}
.u-border-top--danger-thin {
  border-top: 1px solid #C62828;
}
.u-border-top--danger {
  border-top: calc(16px/8) solid #C62828;
}
.u-border-bottom--danger-thin {
  border-bottom: 1px solid #C62828;
}
.u-border-bottom--danger {
  border-bottom: calc(16px/8) solid #C62828;
}
.u-border-left--danger-thin {
  border-left: 1px solid #C62828;
}
.u-border-left--danger {
  border-left: calc(16px/8) solid #C62828;
}
.u-border-right--danger-thin {
  border-right: 1px solid #C62828;
}
.u-border-right--danger {
  border-right: calc(16px/8) solid #C62828;
}
.loader-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 60px 60px;
  z-index: 10;
}
.loader-light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 60px 60px;
  z-index: 10;
}
@media (max-width: 768px) {
  .u-desktop--large,
  .u-desktop,
  .u-tablet,
  .u-desktop--large--block,
  .u-desktop--block,
  .u-tablet--block {
    display: none;
  }
  .u-mobile {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
  }
  .u-mobile--block {
    display: block;
  }
}
@media (min-width: 769px) and (max-width: 960px) {
  .u-desktop--large,
  .u-desktop,
  .u-mobile,
  .u-desktop--large--block,
  .u-desktop--block,
  .u-mobile--block {
    display: none;
  }
  .u-tablet {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
  }
  .u-tablet--block {
    display: block;
  }
}
@media (min-width: 961px) {
  .u-desktop--large,
  .u-tablet,
  .u-mobile,
  .u-desktop--large--block,
  .u-tablet--block,
  .u-mobile--block {
    display: none;
  }
  .u-desktop {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
  }
  .u-desktop--block {
    display: block;
  }
}
@media (min-width: 1281px) {
  .u-desktop,
  .u-tablet,
  .u-mobile,
  .u-desktop--block,
  .u-tablet--block,
  .u-mobile--block {
    display: none;
  }
  .u-desktop--large {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
  }
  .u-desktop--large--block {
    display: block;
  }
}
.tooltip {
  display: inline-block;
  position: relative;
  width: 100%;
}
.tooltip-link {
  display: inline-block;
  position: relative;
}
.tooltip-content {
  display: none;
  content: '';
  padding: calc(16px/2) calc(16px/2);
  line-height: 16px;
  font-size: calc(16px*0.75);
  font-weight: 700;
  color: #fff;
  background-color: #0F1520;
  border-radius: 16px/8;
  box-sizing: border-box;
  margin: 0 calc(16px/4) 0;
  box-shadow: 0 calc(16px/4) 16px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 48px/2;
  cursor: pointer;
  z-index: 2;
}
.tooltip-content:hover {
  display: inline-block;
}
.tooltip-content::after {
  content: '';
  width: 0;
  height: 0;
  border-left: calc(16px/2) solid transparent;
  border-right: calc(16px/2) solid transparent;
  border-top: calc(16px/2) solid #0F1520;
  position: absolute;
  bottom: -16px/2;
  left: 16px;
}
.tooltip-content--dark {
  background-color: #000000;
  color: #fff;
}
.tooltip-content--dark::after {
  border-top-color: #000000;
}
.tooltip-content--heather_grey--4 {
  background-color: #0F1520;
  color: #fff;
}
.tooltip-content--heather_grey--4::after {
  border-top-color: #0F1520;
}
.tooltip-content--heather_grey--3 {
  background-color: #4E565F;
  color: #fff;
}
.tooltip-content--heather_grey--3::after {
  border-top-color: #4E565F;
}
.tooltip-content--heather_grey--2 {
  background-color: #D0D5DF;
  color: #0F1520;
}
.tooltip-content--heather_grey--2::after {
  border-top-color: #D0D5DF;
}
.tooltip-content--heather_grey--1 {
  background-color: #F3F4F6;
  color: #0F1520;
}
.tooltip-content--heather_grey--1::after {
  border-top-color: #F3F4F6;
}
.tooltip-content--white {
  background-color: #fff;
  color: #0F1520;
}
.tooltip-content--white::after {
  border-top-color: #fff;
}
.tooltip-content--black {
  background-color: #000;
  color: #fff;
}
.tooltip-content--black::after {
  border-top-color: #000;
}
.tooltip-content--overlay {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}
.tooltip-content--overlay::after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.tooltip-content--positive {
  background-color: #0CBF65;
  color: #fff;
}
.tooltip-content--positive::after {
  border-top-color: #0CBF65;
}
.tooltip-content--neutral {
  background: #fff;
  box-shadow: inset 0px 0px 0px 1px #0F1520;
  color: #0F1520;
}
.tooltip-content--warning {
  background-color: #E5541A;
  color: #fff;
}
.tooltip-content--warning::after {
  border-top-color: #E5541A;
}
.tooltip-content--warning-light {
  background-color: #efaf11;
  color: #fff;
}
.tooltip-content--warning-light::after {
  border-top-color: #efaf11;
}
.tooltip-content--danger {
  background-color: #C62828;
  color: #fff;
}
.tooltip-content--danger::after {
  border-top-color: #C62828;
}
.tooltip-content--primary {
  background-color: #00B3E3;
  color: #fff;
}
.tooltip-content--primary::after {
  border-top-color: #00B3E3;
}
.tooltip-content--raised {
  top: -16px;
  left: -16px/4;
}
.tooltip-content--fixed {
  position: absolute;
  top: -16px/2;
  right: -20px;
}
.tooltip__close {
  margin-left: calc(16px/4);
}
.tooltip__close::before {
  content: "\f00d";
  font-family: 'FontAwesome';
  display: inline-block;
  vertical-align: middle;
  color: inherit;
}
.tooltip__close:hover {
  opacity: 0.8;
}
.tooltip:hover .tooltip-content {
  display: inline-block;
}
.cms-home .header,
.cms-index-index .header {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  z-index: 10;
  transition: background ease 100ms;
}
.header {
  color: #fff;
  width: 100%;
  position: relative;
  z-index: 10;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
}
.header .ruif-fixed {
  background: rgba(0, 0, 0, 0.8);
}
.header--fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
}
.header .header__search__toggle {
  height: 40px;
  width: 32px;
  position: absolute;
  right: 16px;
  top: 10px;
  font-size: 24px;
  color: #D0D5DF;
  text-align: center;
}
.header .header__search__toggle i {
  vertical-align: middle;
}
.header .header__search__toggle.active i:before {
  content: "\f00d";
}
.header .header__logo {
  padding: 12px 16px 6px calc(16px/4);
}
@media (max-width: 768px) {
  .header .header__logo {
    width: 100%;
    text-align: center;
    padding: calc(16px/2) 16px 0;
  }
}
.header .header__logo .header__logo-image {
  max-height: 40px;
}
.header .header__block {
  padding: 0;
  padding-top: 24px;
  text-align: center;
  color: #D0D5DF;
  position: relative;
  font-size: calc(16px*0.85);
}
.header .header__block:hover {
  color: #fff;
}
.header .header__block::before {
  content: '';
  position: absolute;
  left: 0;
  top: 24px;
  height: 20px;
  width: 1px;
  background: #4E565F;
}
.header .header__search {
  text-align: right;
  padding-top: 16px;
  padding-right: 32px;
}
.header .header__search .search__input {
  background: none;
  border: none;
  outline: none;
  font-size: calc(16px*0.85);
  height: 32px;
  line-height: 32px;
  color: #fff;
}
.header .header__search .search__button {
  background: none;
  border: none;
  color: #D0D5DF;
  cursor: pointer;
}
.header .header__search.active {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 62px;
  text-align: center;
  background: #fff;
  color: #000;
  padding: 16px;
  border-bottom: 1px solid;
}
.header .header__search.active .search__input {
  width: 70%;
  box-sizing: border-box;
  float: left;
  color: #000;
}
.header .header__search.active .search__button {
  float: right;
  box-sizing: border-box;
  font-size: 24px;
  color: #000;
}
.header .mobile__search {
  position: absolute;
  right: 32px;
  top: 16px/4;
  padding: 16px;
  height: 32px;
  width: 32px;
  font-size: calc(16px*1.5);
  box-sizing: border-box;
}
.header .header__login .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 16px 0;
  margin: 0;
  z-index: 90;
  background: #000;
  border: 1px solid #4E565F;
  border-top: 0;
  box-sizing: border-box;
}
.header .header__login .dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.header .header__login:hover .dropdown {
  display: block;
}
.header .header__currency {
  text-align: right;
}
.header__nav--wrapper {
  border-top: 1px solid #4E565F;
}
@media (max-width: 768px) {
  .header__nav--wrapper {
    display: none;
  }
}
.header__login > div > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.notification.cart-count {
  top: calc(16px/2);
  left: 36px;
  z-index: 110;
  position: absolute;
}
@media (min-width: 769px) {
  .notification.cart-count {
    display: none;
  }
}
.global-site-notice {
  width: 100%;
  z-index: 120;
  background: #0F1520;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
  padding: calc(16px/2);
}
@media (max-width: 768px) {
  .global-site-notice {
    position: fixed;
    bottom: 0;
  }
}
.header__nav {
  list-style: none;
  display: -ms-flex;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  position: relative;
  *zoom: 1;
}
.header__nav:before,
.header__nav:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.header__nav:after {
  clear: both;
}
.header__nav li.level0 {
  flex-grow: 1;
  text-align: center;
  z-index: 20;
  float: left;
}
.header__nav li.level0:hover {
  background: #fff;
}
.header__nav li.level0:hover > a {
  color: #000;
}
.header__nav li.level0:hover > a:before {
  display: none;
}
.header__nav li.level0.parent > a span:after {
  content: '\f078';
  display: inline-block;
  font: normal normal normal 12px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: -16px/4 0 0 calc(16px/4);
  font-size: calc(16px*0.65);
  vertical-align: middle;
}
.header__nav li.level0 > a {
  display: inline-block;
  padding: 16px calc(16px/4);
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: calc(16px*0.85);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.header__nav li.level0:first-child > a:before {
  display: none;
}
.header__nav li.level0.nav-attribute-splash-group div.level0 ul.level0 {
  width: 100%;
  margin-left: -16px;
}
.header__nav li.level0.nav-attribute-splash-group div.level0 ul.level0 li.level1 {
  width: 16%;
}
.header__nav li.level0 div.level0 {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  color: #0F1520;
  text-align: left;
  padding: 32px;
  box-sizing: border-box;
  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.35);
}
.header__nav li.level0 div.level0 a.show-all {
  display: block;
  text-align: center;
  background: #00B3E3;
  color: #fff;
  padding: 16px;
  margin: -32px -32px -32px 16px;
  font-weight: 700;
}
.header__nav li.level0 div.level0 ul.level0 {
  display: -ms-flex;
  display: flex;
  flex-grow: 1;
  text-align: left;
  padding: 0;
  margin: 0 0 16px;
  list-style: none;
  flex-wrap: wrap;
  width: 100%;
}
.header__nav li.level0 div.level0 ul.level0 li.level1 {
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.header__nav li.level0 div.level0 ul.level0 li.level1.nav-attribute-splash-page {
  text-align: center;
}
.header__nav li.level0 div.level0 ul.level0 li.level1.nav-attribute-splash-page > a:focus {
  outline: none;
}
.header__nav li.level0 div.level0 ul.level0 li.level1.nav-attribute-splash-page > a .picturefill {
  display: block;
}
.header__nav li.level0 div.level0 ul.level0 li.level1.nav-attribute-splash-page > a .title {
  font-size: calc(16px*0.75);
  font-weight: 700;
  display: block;
  margin-top: calc(16px/4);
}
.header__nav li.level0 div.level0 ul.level0 li.level1 > a {
  font-size: calc(16px*0.75);
  font-weight: 700;
  text-transform: uppercase;
  color: #0F1520;
  margin: 16px/8 0 16px/8;
  display: block;
}
.header__nav li.level0 div.level0 ul.level0 li.level1 > a:hover {
  color: #00B3E3;
}
.header__nav li.level0 div.level0 ul.level0 li.level1[class*="mm-bikes"].level1 {
  margin-bottom: 0;
}
.header__nav li.level0 div.level0 ul.level0 li.level1[class*="mm-bikes"].level1 a {
  padding: calc(16px/2) 16px;
  font-size: calc(16px*0.95);
  margin: 0;
  font-weight: 400;
  color: #000;
  display: inline-block;
}
.header__nav li.level0 div.level0 ul.level0 li.level1[class*="mm-bikes"].level1 a:hover {
  color: #00B3E3;
}
.header__nav li.level0 div.level0 ul.level0 li.level1 ul.level1 {
  display: inline-block;
  flex-grow: 1;
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none;
  vertical-align: top;
}
.header__nav li.level0 div.level0 ul.level0 li.level1 ul.level1 .level2 > a {
  margin: 16px/8 0 calc(16px/4);
  color: #4E565F;
  display: inline-block;
  font-size: calc(16px*0.85);
  font-family: Helvetica, Arial, sans-serif;
}
.header__nav li.level0 div.level0 ul.level0 li.level1 ul.level1 .level2 > a:hover {
  color: #00B3E3;
}
.header__nav li.level0 div.level0 .top-product.products-grid {
  flex-grow: 1;
  width: 32%;
  padding: 32px;
  margin: -32px;
  background-color: #fff;
}
.header__nav li.level0 div.level0 .top-product.products-grid .top-product-title {
  font-size: calc(16px*1.3);
  font-weight: 700;
  display: block;
  text-transform: uppercase;
  margin-top: 12px;
  color: #0F1520;
}
.header__nav li.level0 div.level0 .top-product.products-grid .bike-builder {
  display: none;
}
.header__nav li.level0 div.level0 .top-product.products-grid .images {
  padding: 16px 0;
}
.header__nav li.level0 div.level0 .top-product.products-grid .name a {
  font-size: calc(16px*0.95);
  font-weight: 700;
  display: block;
  color: #000;
  text-transform: uppercase;
}
.header__nav li.level0 div.level0 .top-product.products-grid .price-box p {
  margin: 0;
}
.header__nav li.level0 div.level0 .top-product.products-grid .price-box .regular-price {
  font-size: calc(16px*0.95);
}
.header__nav li.level0 div.level0 .top-product.products-grid .price-box .old-price {
  margin-top: 0;
  font-weight: 400;
}
.header__nav li.level0 div.level0 .top-product.products-grid .price-box .price-save {
  font-weight: 700;
  color: #C62828;
  display: inline-block;
  margin-left: calc(16px/4);
}
.header__nav li.level0 div.level0 .top-product.products-grid .stock {
  font-size: calc(16px*0.75);
  font-weight: 700;
  margin-bottom: calc(16px/2);
  display: block;
  color: #0CBF65;
}
.header__nav li.level0 div.level0 .top-product.products-grid .ratings,
.header__nav li.level0 div.level0 .top-product.products-grid .pmg-button {
  display: none;
}
.header__nav li.level0:hover > div.level0 {
  display: flex;
}
.header-search-autocomplete {
  background: #fff;
  z-index: 100;
  min-width: 288px;
  color: #000;
  font-size: calc(16px*0.95);
  text-align: left;
  padding: 0;
  box-sizing: border-box;
}
.header-search-autocomplete dl {
  margin: 0;
}
.header-search-autocomplete dt {
  font-weight: 700;
  padding: calc(16px/2) 16px calc(16px/2);
  background: rgba(0, 0, 0, 0.03);
}
.header-search-autocomplete dd {
  margin-left: 0;
  padding: 16px;
  font-size: calc(16px*0.85);
  font-weight: 700;
  color: #00B3E3;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
}
.header-search-autocomplete dd .price-box {
  font-size: calc(16px*0.75);
  font-weight: 700;
  color: #0F1520;
}
.header-search-autocomplete dd .price-box .special-price,
.header-search-autocomplete dd .price-box .regular-price .price {
  font-size: calc(16px*0.75);
  color: #0F1520;
  margin: 16px/8 0;
  text-transform: uppercase;
}
.header-search-autocomplete dd .price-box .old-price {
  margin-top: 0;
  font-weight: 400;
}
.header-search-autocomplete dd .price-box .price-save {
  font-weight: 700;
  color: #C62828;
  display: inline-block;
  margin-left: calc(16px/4);
}
.header-search-autocomplete dd:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
.header-search-autocomplete .products-list {
  padding: 16px 16px calc(16px/2) 16px;
  *zoom: 1;
}
.header-search-autocomplete .products-list:before,
.header-search-autocomplete .products-list:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.header-search-autocomplete .products-list:after {
  clear: both;
}
.header-search-autocomplete .products-list:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
.header-search-autocomplete .products-list .product-image-box {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding-right: calc(16px/2);
}
.header-search-autocomplete .products-list .product-shop {
  float: left;
  width: 80%;
  box-sizing: border-box;
}
.header__nav li.level0.mm-bikes div.level0 ul.level0 {
  display: block;
  flex-grow: 1;
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  width: 100%;
}
.header__nav li.level0.mm-bikes div.level0 ul.level0 li.level1 {
  width: 100%;
}
.header__search.active #header-search-autocomplete {
  height: 50vh!important;
  overflow: scroll;
  left: 0!important;
  top: 64px!important;
  border-top: 1px solid #F3F4F6;
  min-width: 100vw!important;
}
#nav li.level0[class*="countdown"] > a,
#nav li.level0[class*="sale"] > a,
#nav li.level0[class*="deal"] > a,
#nav li.level0[class*="outlet"] > a,
#nav li.level0[class*="christmas"] > a,
#nav li.level0[class*="offers"] > a {
  color: #efaf11;
}
.header__cart .count:before {
  content: "\f07a";
  margin-right: 4px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header__cart .count:after {
  content: " - ";
}
.tabs__title {
  display: inline-block;
  padding: calc(16px/2) 16px;
  color: #0F1520;
  font-weight: 700;
  cursor: pointer;
}
.tabs__title--active {
  color: #00B3E3;
}
.tabs__content {
  display: none;
  color: #0F1520;
}
.tabs__content--active {
  display: block;
}
.product__wrapper {
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  box-sizing: border-box;
}
.product__wrapper--scrollable {
  width: 100%;
  padding: 16px 0 calc(16px*5) calc(16px*2);
}
.product__wrapper--scrollable .product__wrapper__inner {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 32px;
  scrollbar-face-color: #4E565F;
  scrollbar-track-color: #F3F4F6;
}
.product__wrapper--scrollable .product__wrapper__inner::-webkit-scrollbar {
  width: 100%;
  height: calc(16px/4);
  border-radius: calc(16px/4);
}
.product__wrapper--scrollable .product__wrapper__inner::-webkit-scrollbar-thumb {
  background: #4E565F;
}
.product__wrapper--scrollable .product__wrapper__inner::-webkit-scrollbar-track {
  background: #F3F4F6;
}
.product__wrapper .product {
  text-align: left;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  width: 100%;
  max-width: 400px;
  padding: 16px;
}
@media screen and (max-width: 480px) {
  .product__wrapper .product {
    padding: 16px 0;
  }
}
.product__wrapper .product__inner {
  padding: 32px 0;
  overflow: hidden;
}
.product__wrapper .product__image {
  display: block;
  padding-top: 75%;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.product__wrapper .product__image img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}
.product__wrapper .product__name {
  margin: 16px 0 calc(16px/2);
  color: #000;
  font-size: calc(16px*1.3);
  line-height: 1.1;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.product__wrapper .product__name:hover {
  color: #008bb0;
}
.product__wrapper .product__description {
  color: #4E565F;
  font-size: calc(16px*0.85);
  font-family: Helvetica, Arial, sans-serif;
}
.product__wrapper .product__price {
  color: #000;
  font-size: calc(16px*0.85);
  line-height: 1.1;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: calc(16px/2) 0 0;
}
.product__wrapper .product__finance {
  color: #0F1520;
  font-weight: 400;
  font-size: calc(16px*0.85);
  font-family: Helvetica, Arial, sans-serif;
}
.product__wrapper .product__rrp {
  color: #4E565F;
  font-weight: 400;
  font-size: calc(16px*0.85);
  text-decoration: line-through;
}
.product__wrapper .product__total {
  color: #4E565F;
  font-weight: 700;
  font-size: calc(16px*0.95);
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
}
.product__wrapper .product__saving {
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  color: #C62828;
  font-weight: 700;
  font-size: calc(16px*0.85);
  text-transform: uppercase;
  margin: 0 0;
}
.product__wrapper .product__swatch__wrapper {
  margin: 0 calc(16px/2);
  font-size: 0;
}
.product__wrapper .product__swatch__wrapper .product__swatch {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  box-shadow: none;
  margin: calc(16px/4);
  border-radius: calc(16px/8);
  background: #fff;
  transition: ease 100ms all;
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}
.product__wrapper .product__swatch__wrapper .product__swatch--selected {
  box-shadow: 0 16px/8 calc(16px/4) 16px/8 rgba(0, 0, 0, 0.2);
}
.product__wrapper .product__swatch__wrapper .product__swatch:hover {
  opacity: 1;
  cursor: pointer;
  transform: scaleY(1.5) scaleX(1.5);
  z-index: 2;
  box-shadow: 0 0 calc(16px/4) 1px rgba(0, 0, 0, 0.13);
}
.product__wrapper .product__swatch__wrapper .product__swatch:hover:before {
  display: none;
}
.product__wrapper .product__reviews {
  font-size: calc(16px*0.85);
  color: #4E565F;
  position: relative;
  margin: 0 0;
  width: 112px;
  height: 40px;
  padding-left: 72px;
}
.product__wrapper .product__reviews:before,
.product__wrapper .product__reviews:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 32px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f005 \f005 \f005 \f005 \f005 ";
  overflow: hidden;
  color: #D0D5DF;
  width: 72px;
}
.product__wrapper .product__reviews--none:after {
  content: "";
  color: #efaf11;
}
.product__wrapper .product__reviews--one:after {
  content: "\f005";
  color: #efaf11;
}
.product__wrapper .product__reviews--two:after {
  content: "\f005 \f005";
  color: #efaf11;
}
.product__wrapper .product__reviews--three:after {
  content: "\f005 \f005 \f005";
  color: #efaf11;
}
.product__wrapper .product__reviews--four:after {
  content: "\f005 \f005 \f005 \f005";
  color: #efaf11;
}
.product__wrapper .product__reviews--five:after {
  content: "\f005 \f005 \f005 \f005 \f005";
  color: #efaf11;
}
.product__wrapper .product__reviews .review__count {
  position: absolute;
  top: calc(16px/2) 0.5;
  font-size: calc(16px*0.85);
  line-height: 1.2;
  text-align: right;
}
.related__product__grid {
  display: grid;
  padding: 0;
  grid-template-columns: 10;
  grid-template-rows: 10;
  border-right: 1px solid #D0D5DF;
  border-bottom: 1px solid #D0D5DF;
}
@media screen and (max-width: 599px) {
  .related__product__grid {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}
.related__product__grid .related__product {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #D0D5DF;
  border-left: 1px solid #D0D5DF;
  position: relative;
  overflow: hidden;
}
.related__product__grid .related__product .related__product__content {
  position: absolute;
  display: block;
  top: 16px;
  left: 16px;
  text-align: left;
}
.related__product__grid .related__product .related__product__image {
  padding: 16px;
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
}
.related__product__grid .related__product:first-child {
  float: none;
  grid-row-start: 1;
  grid-row-end: 7;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .related__product__grid .related__product:first-child {
    grid-row-start: auto;
    grid-row-end: auto;
  }
}
.related__product__grid .related__product:first-child .related__product__content {
  top: auto;
  bottom: 16px;
  text-align: left;
}
.related__product__grid .related__product:nth-child(2) {
  float: none;
  grid-row-start: 8;
  grid-row-end: 10;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .related__product__grid .related__product:nth-child(2) {
    grid-row-start: auto;
    grid-row-end: auto;
  }
}
.related__product__grid .related__product:nth-child(2) .related__product__content {
  top: auto;
  left: auto;
  bottom: 16px;
  right: 16px;
  text-align: right;
}
.related__product__grid .related__product:last-child {
  float: none;
  grid-row-start: 1;
  grid-row-end: 10;
  width: 100%;
}
@media screen and (max-width: 599px) {
  .related__product__grid .related__product:last-child {
    grid-row-start: auto;
    grid-row-end: auto;
  }
}
#v-cookielaw {
  background: #0F1520;
  text-align: center;
  color: #fff;
  font-size: calc(16px*0.75);
  padding: 16px;
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 16px;
  width: calc(16px*12);
  box-sizing: border-box;
  box-shadow: 0 calc(16px/4) calc(16px/4) 0 rgba(0, 0, 0, 0.5);
}
#v-cookielaw .v-button {
  display: inline-block;
  padding: 0 calc(16px/2);
  margin-top: calc(16px/2);
}
#v-cookielaw .v-button.v-accept {
  color: #00B3E3;
  font-weight: 700;
  font-size: calc(16px*0.85);
}
.selectable__wrapper {
  font-size: 0;
  line-height: 0;
  vertical-align: top;
  text-align: left;
  box-sizing: border-box;
  position: relative;
}
.selectable__wrapper--overflow {
  text-align: center;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  text-align: left;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid #4E565F;
  padding: 16px;
}
.selectable__wrapper--overflow ~ .action.action--scrollable {
  display: block;
}
.selectable__wrapper .selectable {
  display: inline-block;
  position: relative;
  border: 1px solid #D0D5DF;
  background: #fff;
  padding: calc(16px/2);
  overflow: hidden;
  text-align: left;
  transition: border 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 30%;
  margin-right: 2%;
  margin-bottom: 2%;
  max-width: calc(16px*20);
  box-sizing: border-box;
  vertical-align: top;
}
@media screen and (max-width: 480px) {
  .selectable__wrapper .selectable {
    margin: 2%;
  }
}
.selectable__wrapper .selectable:hover {
  cursor: pointer;
}
.selectable__wrapper .selectable::before {
  transition: all 180ms cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow: hidden;
  content: '';
  background: none;
  position: absolute;
  left: -calc(16px/4);
  top: -calc(16px/4);
  right: -calc(16px/4);
  bottom: -calc(16px/4);
}
.selectable__wrapper .selectable::after {
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: calc(16px*2);
  text-align: left;
  position: absolute;
  top: 50%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
  color: #fff;
  left: 50%;
}
@media (max-width: 480px) {
  .selectable__wrapper .selectable::after {
    top: unset;
    bottom: calc(16px/2);
    left: 50%;
  }
}
html.no-touchevents .selectable__wrapper .selectable:not(.selectable--selected):hover {
  cursor: pointer;
  border-color: rgba(0, 179, 227, 0.35);
}
html.no-touchevents .selectable__wrapper .selectable:not(.selectable--selected):hover::before {
  background: rgba(0, 179, 227, 0.35);
}
html.no-touchevents .selectable__wrapper .selectable:not(.selectable--selected):hover::after {
  content: '\f00c';
}
html.no-touchevents .selectable__wrapper .selectable:not(.selectable--selected):hover .selectable__config::before {
  color: #fff;
}
.selectable__wrapper .selectable__image {
  width: calc(16px*4.75);
  min-height: calc(16px*4);
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
@media (max-width: 480px) {
  .selectable__wrapper .selectable__image {
    width: calc(16px*2.75);
    margin-top: calc(16px/4);
  }
}
.selectable__wrapper .selectable__config {
  position: absolute;
  top: 0;
  z-index: 6;
  right: 0;
  padding: calc(16px/2);
  text-align: center;
}
.selectable__wrapper .selectable__config::before {
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: '\f013';
  font-size: calc(16px*2);
  text-align: left;
  color: #4E565F;
}
.selectable__wrapper .selectable__config__copy {
  font-size: calc(16px*0.65);
  color: #fff;
  display: block;
}
.selectable:hover .selectable__wrapper .selectable__config::before {
  color: #fff;
}
.selectable__wrapper .selectable__more-info {
  position: absolute;
  bottom: 0;
  z-index: 6;
  right: 0;
  padding: 12px;
  display: none;
}
.selectable__wrapper .selectable__more-info::before {
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: '\f05a';
  font-size: 16px;
  text-align: left;
  color: #fff;
  padding-right: calc(16px/4);
}
.selectable__wrapper .selectable .selectable__content {
  width: 100%;
  display: inline-block;
  float: right;
  box-sizing: border-box;
  vertical-align: top;
  font-size: calc(16px*0.95);
  color: #0F1520;
  line-height: normal;
  text-align: center;
}
.selectable__wrapper .selectable--full .selectable__content {
  margin-left: -80px;
  padding-left: 88px;
  text-align: left;
}
@media (max-width: 480px) {
  .selectable__wrapper .selectable--full .selectable__content {
    margin: 0;
    padding: 0 32px 0 0;
  }
}
.selectable__wrapper .selectable__name {
  font-size: calc(16px*0.95);
  line-height: 1.2;
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-decoration: none;
  padding: 0 56px 16px/8 0;
  color: #00B3E3;
}
@media (max-width: 480px) {
  .selectable__wrapper .selectable__name {
    padding: 16px/8;
  }
}
.selectable__wrapper .selectable__description {
  color: #4E565F;
  font-size: calc(16px*0.85);
  padding: 0 56px calc(16px/2) 0;
}
@media (max-width: 480px) {
  .selectable__wrapper .selectable__description {
    padding: 16px/8;
  }
}
.selectable__wrapper .selectable__price {
  color: #4E565F;
  font-size: calc(16px*0.85);
  font-weight: 600;
  text-align: left;
}
.selectable__wrapper .selectable--full {
  width: 100%;
  margin-right: 0;
  display: block;
  max-width: none;
  padding: 16px;
}
@media screen and (max-width: 480px) {
  .selectable__wrapper .selectable--full {
    margin: 2% 0;
  }
}
.selectable__wrapper .selectable--full::after {
  left: 48px;
  top: 50%;
}
@media screen and (max-width: 480px) {
  .selectable__wrapper .selectable--full::after {
    left: 32px;
    bottom: 0;
    top: auto;
  }
}
.selectable__wrapper .selectable--half {
  width: 48%;
  margin: 1%;
  display: inline-block;
  max-width: none;
  padding: calc(16px/2);
}
@media screen and (max-width: 480px) {
  .selectable__wrapper .selectable--half {
    width: 46%;
    margin: 2% 0;
  }
}
.selectable__wrapper .selectable--half::after {
  left: 36px;
}
.selectable__wrapper .selectable--third {
  width: 31%;
  margin: 1%;
  display: inline-block;
  max-width: none;
  padding: calc(16px/2);
}
@media screen and (max-width: 480px) {
  .selectable__wrapper .selectable--third {
    width: 46%;
    margin: 2% 0;
  }
}
.selectable__wrapper .selectable--third::after {
  left: 36px;
}
.selectable__wrapper .selectable--small {
  display: inline-block;
  position: relative;
  border: 1px solid #F3F4F6;
  background: #fff;
  padding: 16px/4;
  overflow: hidden;
  text-align: left;
}
.selectable__wrapper .selectable--small .selectable__name,
.selectable__wrapper .selectable--small .selectable__description,
.selectable__wrapper .selectable--small .selectable__price {
  padding: 0 calc(16px/2) calc(16px/2);
  text-align: center;
}
.selectable__wrapper .selectable--small .selectable__name {
  font-size: calc(16px*0.85);
}
.selectable__wrapper .selectable--swatch {
  min-width: 0;
  padding: calc(16px/4) 0 calc(16px/2);
  box-sizing: border-box;
  position: relative;
  background: 0 0;
  border: none;
  width: 64px;
  overflow: visible;
}
.selectable__wrapper .selectable--swatch .selectable__graphic {
  width: 100%;
  height: 32px;
  margin: 0 auto;
  border-radius: calc(16px/4);
  box-shadow: inset 0 0 0 16px/8 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.selectable__wrapper .selectable--swatch:hover .selectable__graphic {
  box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.35);
}
.selectable__wrapper .selectable--swatch .selectable__name {
  font-size: calc(16px*0.65);
  font-weight: 400;
  margin: 16px/3 0 0;
  color: #4E565F;
  text-align: center;
  padding: 0;
}
.selectable__wrapper .selectable--swatch .selectable__price {
  position: relative;
  right: auto;
  bottom: auto;
  display: block;
  text-align: center;
  font-size: calc(16px*0.75);
  padding: calc(16px/2);
}
.selectable__wrapper .selectable--swatch::before {
  display: none;
}
.selectable__wrapper .selectable--swatch:hover::after {
  display: block;
  opacity: 0.5;
}
.selectable__wrapper .selectable--swatch::after {
  left: 50%;
  font-size: calc(16px*0.95);
  top: 20px;
  bottom: unset;
  text-shadow: 1px 1px calc(16px/2) rgba(0, 0, 0, 0.2);
}
.selectable__wrapper .selectable--swatch.selectable--selected .selectable__graphic {
  box-shadow: 0px calc(16px/4) calc(16px/2) 0 rgba(0, 0, 0, 0.3);
}
.selectable__wrapper .selectable--swatch.selectable--selected .selectable__name,
.selectable__wrapper .selectable--swatch.selectable--selected .selectable__price {
  color: #00B3E3;
}
.selectable__wrapper .selectable--swatch.selectable--selected::after {
  text-shadow: 1px 1px calc(16px/4) rgba(0, 0, 0, 0.35);
}
.selectable__wrapper .selectable--stacked {
  width: 18%;
  display: inline-block;
  max-width: none;
  padding: 16px calc(16px/2);
  text-align: center;
}
.selectable__wrapper .selectable--stacked::after {
  left: 50%;
  font-size: calc(16px*1.3);
}
.selectable__wrapper .selectable--stacked .selectable__content {
  float: none;
}
.selectable__wrapper .selectable--stacked .selectable__content .selectable__name,
.selectable__wrapper .selectable--stacked .selectable__content .selectable__description {
  color: #4E565F;
  text-align: center;
  padding: 0;
}
.selectable__wrapper .selectable--stacked .selectable__content .selectable__name {
  color: #00B3E3;
}
.selectable__wrapper .selectable--stacked .selectable__content .selectable__description {
  min-height: 0;
}
.selectable__wrapper .selectable--stacked .selectable__content .selectable__price {
  position: relative;
  right: auto;
  bottom: auto;
  display: block;
  text-align: center;
  font-size: calc(16px*0.75);
  padding: calc(16px/2) calc(16px/2) 0;
}
.selectable__wrapper .selectable--stacked.selectable--selected::after {
  display: none;
}
.selectable__wrapper .selectable--stacked.selectable--selected .selectable__description {
  z-index: 1 !important;
}
.selectable__wrapper .selectable--stacked.selectable--selected .notification {
  background: #fff;
  color: #0F1520;
  box-shadow: none;
}
@media screen and (max-width: 1280px) {
  .selectable__wrapper .selectable--stacked {
    width: 31%;
  }
}
@media screen and (max-width: 960px) {
  .selectable__wrapper .selectable--stacked {
    width: 48%;
  }
}
@media screen and (max-width: 768px) {
  .selectable__wrapper .selectable--stacked {
    width: 31%;
  }
}
@media screen and (max-width: 480px) {
  .selectable__wrapper .selectable--stacked {
    width: 46%;
  }
}
.selectable__wrapper .selectable--selected {
  border-color: rgba(0, 179, 227, 0.8);
}
.selectable__wrapper .selectable--selected .selectable__name {
  position: relative;
  z-index: 1;
  color: #fff;
}
.selectable__wrapper .selectable--selected .selectable__description {
  position: relative;
  z-index: 1;
  color: #fff;
}
.selectable__wrapper .selectable--selected .selectable__config {
  display: block;
  cursor: pointer;
}
.selectable__wrapper .selectable--selected .selectable__config::before {
  color: #fff;
}
.selectable__wrapper .selectable--selected .selectable__more-info {
  display: block;
}
.selectable__wrapper .selectable--selected .selectable__price {
  color: #fff;
}
.selectable__wrapper .selectable--selected::before {
  content: '';
  background: rgba(0, 179, 227, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
}
.selectable__wrapper .selectable--selected::after {
  content: '\f00c';
}
.selectable__wrapper .selectable--selected.selectable--swatch {
  border-color: #00B3E3;
}
.selectable__wrapper .selectable--selected.selectable--swatch::after {
  opacity: 1;
}
.selectable__wrapper .selectable--selected.selectable--stacked .selectable__name,
.selectable__wrapper .selectable--selected.selectable--stacked .selectable__description,
.selectable__wrapper .selectable--selected.selectable--stacked .selectable__price {
  color: #fff;
}
.selectable__wrapper .selectable--selected.selectable--stacked .selectable__description,
.selectable__wrapper .selectable--selected.selectable--stacked .selectable__price {
  z-index: -1;
}
.selectable__wrapper .selectable--selected.selectable--custom-color::before {
  border: solid;
  background: unset;
}
.selectable__wrapper .selectable--selected.selectable--custom-color::after {
  top: 50%;
}
.selectable__wrapper .selectable.selectable--disabled {
  background: #ffffff;
  border-color: #ffffff;
  opacity: 0.4;
}
.selectable__wrapper .selectable.selectable--disabled .selectable__content .selectable__description,
.selectable__wrapper .selectable.selectable--disabled .selectable__content .selectable__price,
.selectable__wrapper .selectable.selectable--disabled .selectable__content .selectable__name {
  color: #4E565F;
}
.selectable__wrapper .selectable.selectable--disabled .selectable__content .selectable__config {
  display: none;
}
.selectable__wrapper .selectable.selectable--disabled .notification {
  margin: 0 0 calc(16px/4) 0;
  padding: calc(16px/4) calc(16px/2);
}
.selectable__wrapper .selectable.selectable--disabled .flag {
  bottom: 16px/8;
  left: 0;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
}
html.no-touchevents .selectable__wrapper .selectable.selectable--disabled:not(.selectable--selected):hover {
  border-color: #ffffff;
  cursor: default;
}
html.no-touchevents .selectable__wrapper .selectable.selectable--disabled:not(.selectable--selected):hover::before {
  background: none;
}
html.no-touchevents .selectable__wrapper .selectable.selectable--disabled:not(.selectable--selected):hover::after {
  display: none;
}
.selectable__wrapper .selectable.selectable--incompatible {
  background: #ffffff;
  border-color: #ffffff;
}
.selectable__wrapper .selectable.selectable--incompatible::before {
  position: relative;
}
.selectable__wrapper .selectable.selectable--incompatible .selectable__content .selectable__price,
.selectable__wrapper .selectable.selectable--incompatible .selectable__content .selectable__name {
  color: #4E565F;
}
.selectable__wrapper .selectable.selectable--incompatible .selectable__content .selectable__description,
.selectable__wrapper .selectable.selectable--incompatible .selectable__content .selectable__config {
  display: none;
}
.selectable__wrapper .selectable.selectable--incompatible .notification {
  margin: 0 0 calc(16px/4) 0;
  padding: calc(16px/4) calc(16px/2);
}
.selectable__wrapper .selectable.selectable--incompatible .flag {
  bottom: 16px/8;
  left: 0;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
}
html.no-touchevents .selectable__wrapper .selectable.selectable--incompatible:not(.selectable--selected):hover {
  border-color: #ffffff;
  cursor: default;
}
html.no-touchevents .selectable__wrapper .selectable.selectable--incompatible:not(.selectable--selected):hover::before {
  background: none;
}
html.no-touchevents .selectable__wrapper .selectable.selectable--incompatible:not(.selectable--selected):hover::after {
  display: none;
}
.swatch__wrapper {
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
  padding-bottom: 32px;
}
.swatch__wrapper .swatch {
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  cursor: pointer;
}
.swatch__wrapper .swatch .swatch__colour,
.swatch__wrapper .swatch .swatch__cc {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin-right: calc(16px/2);
  box-sizing: border-box;
}
.swatch__wrapper .swatch .swatch__cc {
  background-color: transparent;
  border-radius: 0;
  background-image: url(/skin/frontend/vortex/RUIF/img/icons/cc-icon-small.svg);
  background-size: cover;
}
.swatch__wrapper .swatch .swatch__copy {
  display: inline-block;
  font-size: calc(16px*0.75);
  padding-top: calc(16px/4);
  color: #4E565F;
}
.swatch__wrapper .swatch.active .swatch__colour {
  border: 16px/8 solid #F3F4F6;
  box-shadow: 0 0 0 16px/8 #D0D5DF;
}
.swatch__wrapper .swatch.active .swatch__copy {
  color: #00B3E3;
}
.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal.modal--absolute {
  position: absolute;
}
.modal .modal__overlay {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.modal .modal__content {
  position: fixed;
  top: 50vh;
  left: 50vw;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
  z-index: 20;
  color: #fff;
}
.modal .modal__content__header {
  padding: 16px;
}
.modal .modal__content__inner {
  padding: 16px;
  max-width: 90vw;
  max-height: 70vh;
  min-width: 320px;
  overflow: scroll;
}
.modal .modal__content__footer {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}
.modal .modal__content--full {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  box-shadow: none;
  overflow: auto;
}
.modal--half {
  min-width: 320px;
  right: auto;
}
.modal--half .modal__overlay {
  width: 30vw;
  min-width: 320px;
  right: auto;
}
.modal--half .modal__content {
  top: 0;
  right: 0;
  left: auto;
  transform: none;
  z-index: 20;
  width: 100%;
  min-width: 0;
  max-height: 100%;
  overflow: auto;
  box-shadow: 0 16px 96px 0 rgba(0, 0, 0, 0.35);
}
.modal--half .modal__content--header {
  border-bottom: none;
}
.modal .modal__close {
  position: absolute;
  z-index: 200;
  top: 16px;
  line-height: 24px;
  right: 16px;
  cursor: pointer;
  background: #00B3E3;
  border-radius: calc(16px/4);
  height: 24px;
  width: 24px;
  text-align: center;
}
.modal .modal__close::before {
  content: "\f00d";
  font-family: 'FontAwesome';
  display: inline-block;
  font-size: 24px;
  vertical-align: middle;
  color: #0B0E14;
  margin-top: -16px/8;
}
.modal .modal__close:hover {
  opacity: 0.8;
}
.modal--light .modal__overlay {
  background: rgba(255, 255, 255, 0.9);
}
.modal--clear .modal__overlay {
  background: none;
}
.hero {
  position: relative;
  height: 90vh;
  width: 100%;
  overflow: hidden;
  display: block;
}
.hero:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.25);
}
.hero__overlay {
  font-display: swap;
  font-family: univia-pro, Helvetica, Arial, sans-serif;
  padding-top: 60px;
  font-size: calc(16px*3);
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1, M12=0, M21=0, M22=1, SizingMethod='auto expand')";
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 3;
}
.hero__overlay--highlight {
  color: #00B3E3;
}
.hero__poster,
.hero__video {
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.hero__video {
  z-index: 1;
  display: none;
}
.hero__video.playing {
  display: block;
}
.notification {
  content: '';
  padding: 2px 8px;
  line-height: 16px;
  font-size: 9.6px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  background: #0F1520;
  border-radius: 2px;
  box-sizing: border-box;
  margin: 0 4px 0;
  position: relative;
  top: -2px;
}
.notification--primary {
  background-color: #00B3E3;
  color: #fff;
}
.blockquote {
  margin: 16px -16px;
  padding: 16px 16px 16px 32px;
  font-size: 14.96px;
  border-left: 2px solid #D0D5DF;
}
.blockquote--primary {
  border-color: #00B3E3;
}
.panel {
  position: relative;
  overflow: hidden;
  word-break: break-word;
}
.panel--black {
  background-color: #000;
  color: #D0D5DF;
}
